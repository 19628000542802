/* eslint-disable @typescript-eslint/indent */
export const nl = {
         device_single_device_manual_control_tab: 'Handbediening',
         service_portal_login_in_correct_qr_code:
           'Device Serial Number is incorrect',
         service_portal_login_in_correct_credentials:
           'Device credendtials are incorrect. Please contact Fortes Administrator.',
         service_portal_login_not_a_registered_service_device:
           'Device with Serial Number {{ SERIAL_NUMBER }} is not registered.',
         devices_unprovisioned: 'Unprovisioned',
         sidebar_unprovisioned_devices: 'Devices - Unprovisioned',
         dashboard_unprovisioned: 'UNPROVISIONED',
         device_single_documentation_warmwater_button: 'Storing Warm Water',
         device_single_documentation_verwarming_button: 'Storing Verwarming',
         service_portal_login_in_serial_number_scan_button:
           'Scan Serial Number',
         service_portal_login_in_heat_interface_unit_scan_button:
           'Scan Heat Interface Unit',
         service_portal_login_in_serial_number_scanned_message:
           'Serial Number is scanned',
         service_portal_login_in_heat_interface_unit_scanned_message:
           'Heat Interface Unit is scanned',
         service_portal_login_in_dont_have_hiu_qr_code_checkbox_field:
           "Don't have HIU QR Code",
         service_portal_login_hiu_autocomplete: 'Heat Interface Unit',
         service_portal_login_in_cant_login_serial_number_checkbox_field:
           "Can't Scan the Serial Number?",
         service_portal_login_serial_number_field: 'Serial Number',
         service_portal_login_serial_number_field_example:
           'Example: 224447355236687709961143',
         service_portal_login_check_serial_number_button: 'Proceed',
         service_portal_login_hiu_autocomplete_default_option: 'Select HIU',
         login_sign_in: 'Log in',
         login_email_address: 'Email adres',
         login_form_password_label: 'Wachtwoord',
         login_forget_password: 'Wachtwoord vergeten',
         login_credentials_error: 'Controleer uw wachtwoord en email adres',
         login_api_error: 'Internal login API Error',
         internal_server_error: 'Internal Server Error',
         /* Forget Password */
         forget_password_title: 'Wachtwoord vergeten',
         forget_password_email_address: 'Email adres',
         forget_password_button: 'Stuur een link om uw wachtwoord te resetten.',
         forget_password_login: 'Terug',
         forgot_password_form_success:
           'E-mail voor wachtwoordherstel wordt doorgestuurd. Controleer uw inbox. Het kan zijn dat de e-mail in uw spam terecht is gekomen.',
         forgot_password_subject: 'Fortes Data Management  - Forgot Password',
         temporary_password_subject:
           'Fortes Data Management - New Account Created',
         forgot_password_required_form_error: 'Email adres is verplicht',
         forgot_password_valid_form_error: 'Geen geldig email adres',
         /* END Forget Password */
         /** Reset Password */
         reset_password_title: 'Reset wachtwoord',
         reset_password_new_password: 'Nieuw wachtwoord',
         reset_password_confirm_password: 'Bevestig wachtwoord',
         reset_password_new_password_required_form_error:
           'Nieuw wachtwoord is verplicht',
         reset_password_confirm_password_required_form_error:
           'Bevestiging van het wachtwoord is verplicht',
         reset_password_passwords_not_matching:
           'De wachtwoorden komen niet overeen',
         reset_password_new_password_form_error:
           'Wachtwoord moet minimaal 8 tekens bevatten met tenminste één hoofdletter, één cijfer en één speciaal teken.',
         reset_password_button: 'Reset wachtwoord',
         reset_password_form_success: 'Het wachtwoord is succesvol gewijzigd.',
         /** End Reset Password */
         /* DASHBOARD */
         dashboard_title: 'Dashboard',
         dashboard_amount_of_devices: 'AANTAL DEVICES',
         dashboard_amount_of_devices_week: 'AANTAL DEVICES VORIGE WEEK',
         dashboard_amount_of_devices_error: 'AANTAL DEVICES MET STORINGEN',
         dashboard_temperature: 'RETOURTEMPERATUUR IN CELCIUS',
         dashboard_device_status: 'Device overzicht',
         dashboard_routing_temperature: 'Temperaturen van alles devices',
         dashboard_routing_temperature_hr: 'Uur',
         dashboard_error_devices:
           'Devices waarvan de regeling in storing staat:',
         dashboard_error: 'ALARM',
         dashboard_online: 'ONLINE',
         dashboard_offline: 'OFFLINE',
         'dashboard organic': 'Organic',
         'dashboard referal': 'Referral',
         dashboard_see_all: 'SEE ALL',
         /* END DASHBOARD */
         /* Sidebar */
         date_range: 'Datum',
         sidebar_dashboard: 'Dashboard',
         sidebar_clients_overview: 'Klant beheer',
         sidebar_clients: 'Klanten',
         sidebar_service_clients: 'Service klant beheer',
         sidebar_devices_overview: 'Device beheer',
         sidebar_devices: 'Device overzicht',
         sidebar_projects: 'Projecten',
         sidebar_presets: 'Presets',
         sidebar_fortes_users: 'Administrators',
         sidebar_fortes_settings: 'Instellingen',
         sidebar_audit: 'Audit',
         sidebar_reports: 'Rapportage',
         sidebar_total_devices: 'Devices - Totaal',
         sidebar_online_devices: 'Devices - Online',
         sidebar_offline_devices: 'Devices - Offline',
         sidebar_error_devices: 'Devices - Alarm',
         sidebar_change_logs: 'Change Logs',
         sidebar_version_title: 'Versie',
         image_upload_success: 'Afeelding is succesvol gewijzigd!',
         image_upload_file_type_error:
           'Alleen JPEG en PNG bestanden zijn toegestaan.',
         image_upload_file_size_error: 'Het bestand moet minder dan 5MB zijn.',
         image_uploading_text: 'Uploaden...',
         /* END Sidebar */
         /* Navbar */
         navbar_search: 'Zoeken',
         navbar_notification: 'Meldingen',
         navbar_no_notification: 'Er zijn geen meldingen',
         navbar_profile: 'Account',
         navbar_setting: 'Account instellingen',
         navbar_logout: 'Uitloggen',
         /* END Navbar */
         active_label: 'ACTIEF',
         deactive_label: 'INACTIEF',
         please_fix_form_errors: 'Corrigeer a.u.b. formulierfouten',
         /* Clients */
         clients: 'Klanten',
         clients_all: 'ALLE',
         clients_active: 'ACTIEF',
         clients_inactive: 'INACTIEF',
         clients_add_new: 'Klant toevoegen',
         clients_filter_search: 'Zoek klant',
         clients_sort_by_filter_label: 'Sorteer op',
         clients_sort_by_value_1: 'Datum(nieuwste)',
         clients_sort_by_value_2: 'Datum(oudste)',
         clients_sort_by_value_3: 'Alfabetisch',
         clients_country_filter_label: 'Locatie',
         clients_filter_reset_button: 'Reset',
         clients_filter_delete_button: 'Verwijder',
         clients_table_name: 'Naam',
         clients_table_country: 'Locatie',
         clients_table_devices: 'Devices',
         clients_table_added: 'Toegevoegd op',
         clients_table_status: 'Status',
         clients_table_row_per_page: 'Resultaten per pagina',
         clients_bread_crumb_main_title: 'Clients',
         add_clients_bread_crumb_first_link: 'Klant toevoegen',
         add_clients_form_header: 'Klant toevoegen',
         edit_clients_bread_crumb_first_link: 'Klant wijzigen',
         edit_clients_form_header: 'Klant wijzigen',
         clients_form_client_name_field: 'Naam',
         clients_form_email_field: 'Email adres',
         clients_form_user_field: 'Gebruiker',
         clients_form_city_field: 'Stad',
         clients_form_country_field: 'Land',
         clients_form_client_3_scale_role_field: '3Scale Rol',
         clients_form_client_3_scale_role_member_label: 'Gebruiker',
         clients_form_client_3_scale_role_admin_label: 'Admin',
         clients_form_allow_customization_field: 'White-labeling toestaan',
         clients_form_add_client_button: 'Klant toevoegen',
         clients_form_edit_client_button: 'Wijzigingen opslaan',
         clients_form_cancel_button: 'Annuleer',
         clients_form_submitting_client_button: 'Verwerken...',
         clients_name_required_form_error: 'Naam is verplicht',
         clients_email_required_form_error: 'Email adres is verplicht',
         clients_api_3scale_role_required_form_error: '3Scale rol is verplicht',
         clients_email_valid_form_error: 'Ongeldig email adres',
         clients_city_required_form_error: 'Stad is verplicht',
         clients_country_required_form_error: 'Land is verplicht',
         clients_add_form_success: 'Klant is succesvol aangemaakt!',
         clients_edit_form_success: 'Klant is succesvol gewijzigd!',
         clients_form_api_error: 'Internal klant - API Server Error',
         clients_form_email_error: 'Het email adres bestaat al',
         clients_no_record_found: 'Geen klant gevonden',
         client_deactivated_message: 'Klant is succesvol gedeactiveerd',
         client_activated_message: 'Klant is succesvol geactiveerd',
         clients_bulk_no_user_is_selected_error_message:
           'Selecteer meerdere rijen om te deactiveren',
         clients_bulk_deactivated_message:
           '{{ USERS_COUNT }} Klant(en) zijn succesvol gedeactiveerd',
         client_single_stats_table_title: 'Status',
         /* END Clients */
         client_single_first_bread_crumb: 'Klanten',
         client_single_second_bread_crumb: 'Toon klant',
         client_single_country_label: 'Land',
         client_single_devices_label: 'Devices',
         client_single_edit_button: 'Wijzigen',
         client_single_users_tab: 'Gebruikers',
         client_single_apis_tab: 'API Keys',
         client_single_customization_tab: 'White label',
         client_single_customization_tab_logo: 'Logo',
         client_single_customization_tab_primary_color: 'Primaire kleur',
         client_single_customization_tab_secondary_color: 'Secondaire kleur',
         client_single_customization_tab_submit_button: 'Wijzigen opslaan',
         client_single_customization_color_schema_success:
           'White labeling is succesvol.',
         client_single_filter_search: 'Zoeken',
         client_single_add_new_user_button: 'Gebruiker toevoegen',
         client_single_add_new_api_key_button: 'API key toevoegen',
         client_single_role_id_filter: 'Rol',
         client_single_users_search: 'Zoeken',
         client_single_filter_reset_button: 'Reset',
         client_single_users_all: 'ALL',
         client_single_users_active: 'ACTIEF',
         client_single_users_inactive: 'INACTIEF',
         client_single_filter_delete_button: 'Verwijderen',
         client_single_users_table_name: 'Naam',
         client_single_users_table_address: 'Adres',
         client_single_users_table_role_id: 'Rol',
         client_single_users_table_status: 'Status',
         client_single_users_table_added: 'Toegevoegd',
         client_single_users_table_no_record_found: 'Geen device gevonden',
         client_single_table_row_per_page: 'Resultaten per pagina',
         client_single_stats_online_devices_label: 'Online devices',
         client_single_stats_error_devices_label: 'Alarm devices',
         client_single_no_user_selected_for_de_activation_message:
           'Selecteer een device om te deactiveren',
         /* END Clients Single */
         /* Service Clients */
         service_clients: 'Service klant',
         service_clients_all: 'ALL',
         service_clients_active: 'ACTIEF',
         service_clients_inactive: 'INACTIEF',
         service_clients_add_new: 'Add new',
         service_clients_filter_search: 'Zoeken',
         service_clients_sort_by_filter_label: 'Sorteren op',
         service_clients_sort_by_value_1: 'Datum (nieuwste)',
         service_clients_sort_by_value_2: 'Datum (oudste)',
         service_clients_sort_by_value_3: 'Alfabetisch',
         service_clients_country_filter_label: 'Land',
         service_clients_filter_reset_button: 'Reset',
         service_clients_filter_delete_button: 'Verwijderen',
         service_clients_table_name: 'Naam',
         service_clients_table_country: 'Land',
         service_clients_table_devices: 'Devices',
         service_clients_table_added: 'Toegevoegd',
         service_clients_table_status: 'Status',
         service_clients_table_row_per_page: 'Resultaten per pagina',
         service_clients_bread_crumb_main_title: 'Service klanten',
         add_service_clients_bread_crumb_first_link: 'Service klant toevoegen',
         add_service_clients_form_header: 'Service klant toevoegen',
         edit_service_clients_bread_crumb_first_link: 'Service klant wijzigen',
         edit_service_clients_form_header: 'Service klant wijzigen',
         service_clients_form_service_client_name_field: 'Naam',
         service_clients_form_email_field: 'Email adres',
         service_clients_form_client_field: 'Klant',
         service_clients_form_user_field: 'Gebruiker',
         service_clients_form_city_field: 'Stad',
         service_clients_form_country_field: 'Land',
         service_clients_form_add_service_client_button: 'Wijzigingen opslaan',
         service_clients_form_edit_service_client_button: 'Wijzigingen opslaan',
         service_clients_form_cancel_button: 'Annuleer',
         service_clients_form_submitting_service_client_button: 'Verwerken...',
         service_clients_name_required_form_error: 'Naam is verplicht',
         service_clients_email_required_form_error: 'Email is verplicht',
         service_clients_focal_user_id_required_form_error:
           'Focal gebruiker is verplicht',
         service_clients_email_valid_form_error: 'Uw email adres is ongeldig',
         service_clients_city_required_form_error: 'Stad is verplicht',
         service_clients_client_required_form_error: 'Klant is verplicht',
         service_clients_country_required_form_error: 'Land is verplicht',
         service_clients_add_form_success:
           'Service klant is succesvol aangemaakt!',
         service_clients_edit_form_success:
           'Service klant is succesvol gewijzigd!',
         service_clients_form_api_error:
           'Internal Service klant - API Server Error',
         service_clients_form_email_error: 'Email adres is reeds in gebruik',
         service_clients_no_record_found: 'Geen sub klant gevonden',
         service_client_deactivated_message:
           'Service klant is succesvol gedeactiveerd',
         service_client_activated_message:
           'Service klant is succesvol geactiveerd',
         service_clients_bulk_no_user_is_selected_error_message:
           'Selecteer een device om te deactiveren',
         service_clients_bulk_deactivated_message:
           '{{ USERS_COUNT }} Service klant(en) zijn succesvol gedeactiveerd',
         service_client_single_stats_table_title: 'Status',
         /* END Service Clients */
         service_client_single_first_bread_crumb: 'Service klanten',
         service_client_single_second_bread_crumb: 'Service klant weergeven',
         service_client_single_country_label: 'Land',
         service_client_single_devices_label: 'Devices',
         service_client_single_edit_button: 'Wijzigen',
         service_client_single_users_tab: 'Gebruikers',
         service_client_single_apis_tab: 'API Keys',
         service_client_single_filter_search: 'Zoeken',
         service_client_single_add_new_user_button: 'Gebruiker toevoegen',
         service_client_single_add_new_api_key_button: 'API key toevoegen',
         service_client_single_role_id_filter: 'Rol',
         service_client_single_users_search: 'Zoeken',
         service_client_single_filter_reset_button: 'Reset',
         service_client_single_users_all: 'ALL',
         service_client_single_users_active: 'ACTIEF',
         service_client_single_users_inactive: 'INACTIEF',
         service_client_single_filter_delete_button: 'Verwijderen',
         service_client_single_users_table_name: 'Naam',
         service_client_single_users_table_address: 'Adres',
         service_client_single_users_table_role_id: 'Rol',
         service_client_single_users_table_status: 'Status',
         service_client_single_users_table_added: 'Toegevoegd',
         service_client_single_users_table_no_record_found:
           'Geen device gevonden',
         service_client_single_table_row_per_page: 'Resultaten per pagina',
         service_client_single_stats_active_devices_label: 'Online devices',
         service_client_single_stats_error_devices_label: 'Alarm devices',
         service_client_single_no_user_selected_for_de_activation_message:
           'Please select records to deactivate',
         /* END Service Clients Single */
         /* Gebruikers */
         users_bread_crumb_main_title: 'Klanten',
         add_users_bread_crumb_first_link: 'Gebruiker toevoegen',
         add_users_form_header: 'Gebruiker toevoegen',
         edit_users_bread_crumb_first_link: 'Gebruiker wijzigen',
         edit_users_form_header: 'Wijzigen user',
         users_form_first_name_field: 'Voornaam',
         users_form_last_name_field: 'Achternaam',
         users_form_email_field: 'Email adres',
         users_form_official_email_field: 'Email adres',
         users_form_phone_field: 'Telefoon nummer',
         users_form_official_phone_number_field: 'Telefoon nummer',
         users_form_password_field: 'Wachtwoord',
         users_form_role_id_field: 'Rol',
         users_form_select_role_id_field: 'Selecteer rol',
         users_form_street_number_field: 'Straat',
         users_form_house_number_field: 'Huisnummer',
         users_form_neighbourhood_field: 'Neighbourhood',
         users_form_postal_code_field: 'Postcode',
         users_form_city_field: 'Stad',
         users_form_country_field: 'Land',
         users_form_email_verification_field: 'Email bevestiging',
         users_form_email_verification_sub_title_field:
           'Als u deze knop uitschakelt, wordt automatisch een bevestigingsmail naar de gebruiker verzonden',
         users_form_add_user_button: 'Gebruiker toevoegen',
         users_form_edit_user_button: 'Wijzigingen opslaan',
         users_form_cancel_button: 'Annuleer',
         users_form_submitting_user_button: 'Verwerken...',
         users_first_name_required_form_error: 'Voornaam is verplicht',
         users_last_name_required_form_error: 'Achternaam is verplicht',
         users_email_required_form_error: 'Email is verplicht',
         users_email_valid_form_error: 'Uw email adres is ongeldig',
         users_password_required_form_error: 'Wachtwoord is verplicht',
         users_country_required_form_error: 'Land is verplicht',
         users_role_id_required_form_error: 'Rol is verplicht',
         users_password_form_error:
           'Wachtwoord moet minimaal 8 tekens bevatten met tenminste één hoofdletter, één cijfer en één speciaal teken',
         users_add_form_success: 'Gebruiker is succesvol aangemaakt!',
         users_edit_form_success: 'Gebruiker is succesvol gewijzigd!',
         users_form_api_error: 'Internal Gebruikers - API Server Error',
         users_form_email_error: 'Email adres bestaat al',
         users_no_record_found: 'Geen gebruikers gevonden',
         user_deactivated_message: 'Gebruiker is succesvol gedeactiveerd',
         user_activated_message: 'Gebruiker is succesvol geactiveerd',
         user_email_verified_title: 'Email adres bevestigd',
         user_email_verified_sub_title:
           'Als u deze knop uitschakelt, wordt automatisch een bevestigingsmail naar de gebruiker verzonden',
         users_bulk_no_user_is_selected_error_message:
           'Selecteer gebruiker(s) om te deactiveren',
         users_bulk_deactivated_message:
           '{{ USERS_COUNT }} Gebruikers zijn succesvol gedeactiveerd',
         /** Projects */
         projects: 'Projecten',
         projects_all: 'ALLE',
         projects_active: 'ACTIEF',
         projects_inactive: 'INACTIEF',
         projects_unassigned: 'NIET TOEGEWEZEN',
         projects_add_new: 'Nieuw project',
         projects_filter_search: 'Project zoeken',
         projects_sort_by_filter_label: 'Sorteren op',
         projects_sort_by_value_1: 'Datum (nieuwste)',
         projects_sort_by_value_2: 'Datum (oudste)',
         projects_sort_by_value_3: 'Alfabetisch',
         projects_country_filter_label: 'Land',
         projects_filter_reset_button: 'Reset',
         projects_filter_delete_button: 'Verwijderen',
         projects_table_name: 'Naam project',
         projects_table_batch_number: 'Batchnummer',
         projects_table_client: 'Toegewezen klant',
         projects_table_devices: 'Devices',
         projects_table_added: 'Toegevoegd op',
         projects_table_status: 'Status',
         projects_table_row_per_page: 'Resultaten per pagina',
         projects_bread_crumb_main_title: 'Projecten',
         add_projects_bread_crumb_first_link: 'Project toevoegen',
         add_projects_form_header: 'Project toevoegen',
         edit_projects_bread_crumb_first_link: 'Project wijzigen',
         edit_projects_form_header: 'Project wijzigen',
         projects_form_project_name_field: 'Projectnaam',
         projects_form_email_field: 'Email adres',
         projects_form_client_field: 'Klant',
         projects_form_service_client_field: 'Service klant',
         projects_form_user_field: 'Gebruiker',
         projects_form_description_field: 'Omschrijving (optioneel)',
         projects_form_add_project_button: 'Project opslaan',
         projects_form_edit_project_button: 'Wijzigingen opslaan',
         projects_form_cancel_button: 'Annuleer',
         projects_form_submitting_service_client_button: 'Verwerken...',
         projects_name_required_form_error: 'Naam is verplicht',
         projects_form_unqiue_project_order_number_error:
           'Project Order Number moet uniek zijn',
         projects_batch_number_required_form_error: 'Batch nummer is verplicht',
         projects_client_required_form_error: 'Klant is verplicht',
         projects_description_required_form_error: 'Omschrijving is verplicht',
         projects_add_form_success: 'Project is succesvol aangemaakt!',
         projects_edit_form_success: 'Project is succesvol gewijzigd!',
         projects_form_api_error: 'Internal projects - API Server Error',
         projects_no_record_found: 'Geen projecten gevonden',
         projects_deactivated_message: 'Project is succesvol gedeactiveerd',
         projects_activated_message: 'Project is succesvol geactiveerd',
         projects_bulk_no_user_is_selected_error_message:
           'Selecteer project(en) om te deactiveren',
         projects_bulk_deactivated_message:
           '{{ USERS_COUNT }} Projects zijn succesvol gedeactiveerd',
         projects_single_stats_table_title: 'Status',
         /** Devices */
         devices: 'Device overzicht',
         devices_all: 'ALLE',
         devices_active: 'Online',
         devices_inactive: 'Offline',
         devices_error: 'ALARM',
         devices_register_enabled: 'Ingeschakeld',
         devices_register_disabled: 'Uitgeschakeld',
         devices_add_new: 'Nieuw device',
         devices_name: 'Naam',
         devices_address: 'Installatie adres',
         devices_description: 'Type afleverset',
         devices_client: 'Klant',
         devices_project: 'Project',
         devices_status: 'Status',
         device_is_online: 'Device is "online"',
         device_is_offline: 'Device is "offline"',
         devices_last_viewed: 'Laatste bericht',
         devices_action: 'Actie',
         devices_search: 'Zoeken',
         devices_row_per_page: 'Resultaten per pagina',
         device_rssi_title: 'RSSI',
         device_status_reg_105_no_error_255: 'Geen alarm',
         device_status_menu_list: JSON.stringify([
           {
             key: 'operating_state',
             value: 'Bedrijfstatus',
             permission: 'operating-state-tab-read',
           },
           {
             key: 'settings_hot_water',
             value: 'Instellingen tapwater',
             permission: 'settings-hot-water-tab-read',
           },
           {
             key: 'settings_heating_and_cooling',
             value: 'Instellingen verwarmen en koelen',
             permission: 'settings-heating-and-cooling-tab-read',
           },
           {
             key: 'manual_control',
             value: 'Handbediening',
             permission: 'manual-control-tab-read',
           },
           {
             key: 'm_bus_meters',
             value: 'Energiemeters (M-Bus)',
             permission: 'm-bus-meters-tab-read',
           },
           {
             key: 'live_hot_water',
             value: 'Livelog tapwater',
             permission: 'livelog-hot-water-tab-read',
           },
           {
             key: 'live_heating',
             value: 'Livelog verwarming',
             permission: 'livelog-heating-tab-read',
           },
           {
             key: 'live_cooling',
             value: 'Livelog koelen',
             permission: 'livelog-cooling-tab-read',
           },
         ]),
         device_status_menu_keys_list: JSON.stringify([
           'operating_state',
           'settings_hot_water',
           'settings_heating_and_cooling',
           'manual_control',
           'm_bus_meters',
           'live_hot_water',
           'live_heating',
           'live_cooling',
         ]),
         devices_filter_search: 'Device zoeken',
         devices_sort_by_filter_label: 'Sorteren op',
         devices_sort_by_value_1: 'Datum (nieuwste)',
         devices_sort_by_value_2: 'Datum (oudste)',
         devices_sort_by_value_3: 'Alfabetisch',
         devices_country_filter_label: 'Land',
         devices_filter_reset_button: 'Reset',
         devices_filter_delete_button: 'Verwijderen',
         devices_table_name: 'Naam',
         devices_table_firmware: 'Firmware',
         devices_table_device_type: 'Device type',
         devices_table_batch_number: 'Batch nummer',
         devices_table_client: 'Clients',
         devices_table_devices: 'Devices',
         devices_table_added: 'Toegevoegd',
         devices_table_status: 'Status',
         devices_table_row_per_page: 'Resultaten per pagina',
         devices_bread_crumb_main_title: 'Devices',
         add_devices_bread_crumb_first_link: 'Device toevoegen',
         add_devices_form_header: 'Device toevoegen',
         edit_devices_bread_crumb_first_link: 'Device wijzigen',
         edit_devices_form_header: 'Device wijzigen',
         devices_form_device_information_tab: 'Device info',
         devices_form_name_field: 'Device naam',
         devices_form_type_field: 'Device type',
         devices_form_ip_address_field: 'IP Adres',
         devices_form_preset_field: 'Preset',
         devices_form_project_field: 'Project',
         devices_form_client_field: 'Klant',
         devices_form_batch_number_field: 'Batch nummer',
         devices_form_serial_number_field: 'Serienummer Mercurius',
         devices_form_box_serial_number_field: 'Serienummer afleverset',
         devices_form_firmware_field: 'Firmwareversie',
         devices_form_description_field: 'Type afleverset',
         devices_form_device_address_tab: 'Installatie adres',
         devices_form_street_number_field: 'Straat',
         devices_form_house_number_field: 'Huisnummer',
         devices_form_postal_code_field: 'Postcode',
         devices_form_neighbourhood_field: 'Extra informatie',
         devices_form_city_field: 'Stad',
         devices_form_country_field: 'Land',
         devices_form_floor_number_field: 'Verdieping',
         devices_form_building_number_field: 'Bouwnummer',
         devices_form_additional_information_tab: 'Extra informatie',
         devices_form_sn_heat_unit_field: 'Serienummer afleverset',
         devices_form_article_number_field: 'Artikelnummer afleverset',
         devices_form_prod_order_nr_week_field: 'Productie order nummer',
         devices_form_serial_number_mercurius_field: 'Serienummer Mercurius',
         devices_form_sim_card_number_field: 'Simkaart nummer',
         devices_form_customer_ref_field: 'Referentie klant',
         devices_form_production_date_field: 'Productiedatum',
         devices_form_order_number_field: 'Ordernummer',
         devices_form_product_number_field: 'Artikelnummer',
         devices_form_model_number_field: 'Model nummer',
         devices_form_note_field: 'Notitie (optioneel)',
         devices_form_settings_tab: 'Instellingen',
         devices_form_device_interval_field: 'Interval',
         devices_form_is_device_enabled_sensor_field: 'Inschakelen sensor data',
         devices_form_is_device_activated_field: 'Deactivate device',
         devices_form_mbus_tab: 'M-Bus',
         devices_form_is_device_enabled_heat_meter_field:
           'Inschakelen warmtemeter',
         devices_form_is_device_enabled_cool_meter_field:
           'Inschakelen koudemeter',
         devices_form_is_device_enabled_electric_meter_field:
           'Inschakelen electricity meter',
         devices_form_is_device_enabled_bi_directional_meter_field:
           'Inschakelen heat/koudemeter',
         devices_form_is_device_enabled_water_meter_field:
           'Inschakelen watermeter',
         devices_form_is_device_enabled_mbus_search_meter_button: 'Zoek M-Bus',
         devices_form_add_device_button: 'Device toevoegen',
         devices_form_edit_device_button: 'Wijzigingen opslaan',
         devices_form_cancel_button: 'Annuleer',
         devices_form_submitting_service_client_button: 'Verwerken...',
         devices_name_required_form_error: 'Naam is verplicht',
         devices_batch_number_required_form_error: 'Batch number is verplicht',
         devices_serial_number_required_form_error: 'Serienummer is verplicht',
         devices_sn_heat_number_numeric_form_error:
           'Serienummer van de afleverset moet een nummer zijn',
         devices_article_number_form_error: 'Artikelnummer is verplicht',
         devices_prod_order_nr_week_form_error:
           'Productie order nummer is verplicht',
         devices_serial_number_mercurius_form_error:
           'Serienummer Mercurius is verplicht',
         devices_sim_card_number_form_error: 'Simkaart nummer is verplicht',
         devices_device_interval_numeric_form_error:
           'Interval moet een getal zijn',
         devices_client_required_form_error: 'Klant is verplicht',
         devices_project_required_form_error: 'Project is verplicht',
         devices_preset_required_form_error: 'Preset is verplicht',
         devices_description_required_form_error: 'Omschrijving is verplicht',
         devices_add_form_success: 'Device is succesvol aangemaakt!',
         devices_edit_form_success: 'Device is succesvol gewijzigd!',
         devices_form_unique_error:
           'Serienummer Afleverset en serienummer Mercurius moeten uniek zijn',
         devices_form_api_error: 'Internal devices - API Server Error',
         devices_no_record_found: 'Geen devices gevonden',
         devices_deactivated_message: 'Device is succesvol gedeactiveerd',
         devices_activated_message: 'Device is succesvol geactiveerd',
         devices_search_mbus_registers_success:
           'Het bericht is succesvol naar het apparaat verzonden.',
         devices_search_mbus_registers_missing_serial_number_error:
           'Vul het serienummer in om verder te gaan.',
         devices_bulk_no_user_is_selected_error_message:
           'Selecteer gebruikers om te deactiveren',
         devices_bulk_deactivated_message:
           '{{ USERS_COUNT }} Devices zijn succesvol gedeactiveerd',
         // Devices Single
         device_single_page_title: 'Device details',
         devices_single_bread_crumb_main_title: 'Device overzicht',
         devices_single_bread_crumb_first_link: 'Devicepagina',
         devices_single_basic_details_client: 'KLANT',
         devices_single_basic_details_serial_number: 'SERIENUMMER MERCURIUS',
         devices_single_basic_details_firmware: 'FIRMWARE',
         devices_single_basic_details_type: 'DEVICE TYPE',
         devices_single_edit_device_button: 'Device wijzigen',
         device_single_error_status: 'Error',
         device_single_online_status: 'Online',
         device_single_offline_status: 'Offline',
         device_single_rss_device_status: 'Device status',
         device_single_rss_signal_strength: 'Signaal sterkte',
         device_single_temperature_tab: 'Historie',
         device_single_temperature_title:
           'De historische data van de afleverset',
         device_single_temperature_hr: 'uur',
         device_single_registers_tab: 'Registers',
         device_single_event_tab: 'Events',
         device_single_device_details_tab: 'Info',
         device_single_device_api_timer_seconds_label: 'Seconden',
         device_single_device_api_timer_minute_label: 'Minuut',
         device_single_device_api_timer_minutes_label: 'Minuten',
         device_single_recall_device_status_api_timer_field: 'API Interval',
         devices_single_last_seen: 'Laatste bericht',
         devices_single_fetch_registers: 'VERNIEUW DATA',
         fetch_registers_success:
           'Het verzoek is succesvol verzonden naar het device.',
         write_registers_success:
           'Schrijfbericht is succesvol verzonden naar device.',
         write_registers_error: 'Wijzig de waarde om door te gaan.',
         fetch_registers_m_bus_meters_not_supported:
           'M-Bus Meters worden momenteel niet herkend. Controleer de aansluiting en/of de M-Bus module van de energiemeter.',
         // Operating Status
         business_situation_operating_status_TR03_title:
           'Bedrijfsstatus afleverset',
         device_status_nr: 'NR',
         device_status_name: 'Naam',
         device_status_value: 'Value',
         business_situation_operating_status_TR03_100: 'Verwarming status',
         business_situation_operating_status_TR03_100_list: JSON.stringify({
           list: {
             0: 'Stand-by',
             1: 'Verzoek',
             2: 'Vraag',
             3: 'Na circuleren',
             4: 'Uit',
             5: 'Voor circuleren',
           },
         }),
         business_situation_operating_status_TR03_101: 'Tapwater status',
         business_situation_operating_status_TR03_101_list: JSON.stringify({
           list: {
             0: 'Stand-by',
             1: 'Verzoek (tijdelijk onderbroken)',
             2: 'Vraag',
             3: 'Na circuleren',
             4: 'Uit',
           },
         }),
         business_situation_operating_status_TR03_102: 'Warmhoud status',
         business_situation_operating_status_TR03_102_list: JSON.stringify({
           list: {
             0: 'Stand-by',
             1: 'Actief',
           },
         }),
         business_situation_operating_status_TR03_103: 'Anti Legionella status',
         business_situation_operating_status_TR03_103_list: JSON.stringify({
           list: {
             0: 'Stand-by',
             1: 'Verzoek (tijdelijk onderbroken)',
             2: 'Vraag',
             3: 'Na circuleren',
             4: 'Uit',
           },
         }),
         business_situation_operating_status_TR03_104: 'Koelen status',
         business_situation_operating_status_TR03_104_list: JSON.stringify({
           list: {
             0: 'Stand-by',
             1: 'Verzoek (tijdelijk onderbroken)',
             2: 'Initialiseren',
             3: 'Vraag',
             4: 'Na circulatie',
             5: 'Voor circulatie',
           },
         }),
         business_situation_operating_status_TR03_1802: 'Elektrisch element',
         business_situation_operating_status_TR03_1802_list: JSON.stringify({
           list: {
             0: 'Uit',
             1: 'Aan',
           },
         }),
         business_situation_operating_status_TR03_105: 'Alarm',
         business_situation_operating_status_TR03_106: 'Software versie',
         business_situation_operating_status_TR03_107:
           'Tapwater regelafsluiter (M1)',
         business_situation_operating_status_TR03_108:
           'Verwarmen/koelen regelafsluiter (M2)',
         business_situation_operating_status_TR03_109: 'Circulatie pomp',
         business_situation_operating_status_TR03_109_list: JSON.stringify({
           list: {
             0: 'Uit',
             1: 'Circuleren',
           },
         }),
         business_situation_operating_status_TR03_110: '6-weg kogelafsluiter',
         business_situation_operating_status_TR03_110_list: JSON.stringify({
           list: {
             0: 'Stand: Verwarmen',
             1: 'Stand: Koelen',
           },
         }),
         business_situation_operating_status_TR03_112_list: JSON.stringify({
           list: {
             0: 'Heating or demand',
             1: 'Cooling',
           },
         }),
         business_situation_operating_status_TR03_113: 'Ruimte thermostaat',
         business_situation_operating_status_TR03_113_list: JSON.stringify({
           list: {
             1: 'Geen warmte vraag',
             2: 'Warmte vraag',
           },
         }),
         business_situation_operating_status_TR03_114: 'OpenTherm Indicatie',
         business_situation_operating_status_TR03_114_list: JSON.stringify({
           list: {
             0: 'Inactieve verbinding',
             1: 'Actieve verbinding',
           },
         }),
         business_situation_operating_status_TR03_115: 'Toesteltype',
         // Business situation
         business_situation_measured_values_TR03_title: 'Meetwaarden',
         business_situation_measured_values_TR03_200: 'Volumestroom tapwater',
         business_situation_measured_values_TR03_201: 'T-Tapwater',
         business_situation_measured_values_TR03_202: 'T-Primaire aanvoer',
         business_situation_measured_values_TR03_203:
           'T-Primaire retour (T-Booster voor TE-Booster toestellen)',
         business_situation_measured_values_TR03_205: 'T-Secundaire aanvoer',
         business_situation_measured_values_TR03_206: 'T-Secundaire retour',
         business_situation_measured_values_TR03_204:
           'Berekende aanvoertemperatuur (setpoint)',
         business_situation_measured_values_TR03_207:
           'Ruimte temperatuur(OpenTherm)',
         business_situation_measured_values_TR03_208:
           'Ruimte setpoint (OpenTherm)',
         business_situation_measured_values_TR03_210:
           'Waterdruk in de binnenhuisinstallatie',
         // Operating time
         business_situation_operating_time_TR03_title: 'Bedrijfstijden',
         business_situation_operating_time_TR03_800:
           'Totale bedrijfstijd afleverset',
         business_situation_operating_time_TR03_801:
           'Totale bedrijfstijd warmte-vraag',
         business_situation_operating_time_TR03_802:
           'Totale bedrijfstijd koude-vraag',
         business_situation_operating_time_TR03_803:
           'Totale bedrijfstijd tapwater-vraag',
         business_situation_operating_time_TR03_804: 'Aantal tappingen',
         business_situation_operating_time_TR03_805:
           'Totale volume tapwater (let op! dit is een indicatie)',
         business_situation_operating_time_TR03_806: 'Stroomonderbrekingen',
         // Warmtapwater
         settings_dhw_warm_tap_water_TR03_title: 'Instellingen tapwater',
         settings_dhw_warm_tap_water_TR03_300: 'Tapwater setpoint',
         settings_dhw_warm_tap_water_TR03_301: 'Warmhoudmodus',
         settings_dhw_warm_tap_water_TR03_301_list: JSON.stringify({
           list: {
             0: 'Uit',
             1: 'Anti-vries programma',
             2: 'Eco programma',
             3: 'Comfort programma',
             4: 'Dynamisch (aanbevolen)',
           },
         }),
         settings_dhw_warm_tap_water_TR03_302:
           'Warmhoudsetpoint (comfort stand)',
         settings_dhw_warm_tap_water_TR03_303: 'Tapdrempel',
         settings_dhw_warm_tap_water_TR03_304: 'Tapwater inschakelen',
         settings_dhw_warm_tap_water_TR03_304_list: JSON.stringify({
           list: {
             0: 'Uit',
             1: 'Aan',
           },
         }),
         // Thermal disinfection
         settings_thermal_disinfection_TR03_title:
           'Instellingen anti-legionella',
         settings_thermal_disinfection_TR03_400:
           'Anti-Legionella ingeschakeling',
         settings_thermal_disinfection_TR03_400_list: JSON.stringify({
           list: {
             0: 'Uit',
             1: 'Aan',
           },
         }),
         settings_thermal_disinfection_TR03_401: 'Anti-Legionella setpoint',
         settings_thermal_disinfection_TR03_402: 'Anti-Legionella brand tijd',
         settings_thermal_disinfection_TR03_403: 'Anti-Legionella wacht tijd',
         // TE-Booster
         settings_dhw_te_booster_TR03_title: 'Instellingen TE-Booster',
         settings_dhw_te_booster_TR03_1800: 'TE-Booster',
         settings_dhw_te_booster_TR03_1800_list: JSON.stringify({
           list: {
             0: 'Uitgeschakeld',
             1: 'Ingeschakeld',
           },
         }),
         settings_dhw_te_booster_TR03_1804: 'TE-Booster setpoint',
         settings_dhw_te_booster_TR03_1805: 'TE-Booster setpoint hysterese',
         settings_dhw_te_booster_TR03_1812: 'TE-Booster setpoint timeout',
         settings_dhw_te_booster_TR03_1813: 'TE-Booster setpoint timer',
         settings_dhw_te_booster_TR03_1815: 'TE-Booster activatie drempel',
         settings_dhw_te_booster_TR03_1816: 'TE-Booster getapt volume',
         // Heating
         heating_and_cooling_settings_heating_TR03_title:
           'Instellingen verwarmen',
         heating_and_cooling_settings_heating_TR03_500:
           'Maximale setpoint verwarmen',
         heating_and_cooling_settings_heating_TR03_501:
           'Minimale setpoint verwarmen',
         heating_and_cooling_settings_heating_TR03_506:
           'Maximale retourtemperatuur verwarmen',
         heating_and_cooling_settings_heating_TR03_507:
           'Compensatie factor van de primaire retour',
         heating_and_cooling_settings_heating_TR03_508:
           'Delta primary-secondary setpoint',
         heating_and_cooling_settings_heating_TR03_510:
           'Maximaalbeveiliging verwarmen',
         heating_and_cooling_settings_heating_TR03_509:
           'Minimum primaire-secundaire delta',
         heating_and_cooling_settings_heating_TR03_514: 'Verwarmen setpoint',
         heating_and_cooling_settings_heating_TR03_515: 'Verwarmen inschakelen',
         heating_and_cooling_settings_heating_TR03_515_list: JSON.stringify({
           list: {
             0: 'Uit',
             1: 'aan',
           },
         }),
         // Cooling
         heating_and_cooling_settings_cooling_TR03_title: 'Instellingen koelen',
         heating_and_cooling_settings_cooling_TR03_600: 'Koelen setpoint',
         heating_and_cooling_settings_cooling_TR03_601:
           'Minimale primaire retour',
         heating_and_cooling_settings_cooling_TR03_602:
           'Compensatiefactor van de primaire retour',
         heating_and_cooling_settings_cooling_TR03_605:
           'Maximaalbeveiliging koelen',
         heating_and_cooling_settings_cooling_TR03_606:
           'Minimale setpoint koelen',
         heating_and_cooling_settings_cooling_TR03_607:
           'Maximale setpoint koelen',
         heating_and_cooling_settings_cooling_TR03_611: 'Koelen inschakelen',
         heating_and_cooling_settings_cooling_TR03_611_list: JSON.stringify({
           list: {
             0: 'Uit',
             1: 'Aan',
           },
         }),
         // Secondary General
         heating_and_cooling_settings_secondary_general_TR03_title:
           'Algemene instellingen',
         heating_and_cooling_settings_secondary_general_TR03_512:
           'Minimale druk',
         heating_and_cooling_settings_secondary_general_TR03_513:
           'Maximale druk',
         heating_and_cooling_settings_secondary_general_TR03_603:
           'Wachttijd omschakelen verwarmen-koelen',
         heating_and_cooling_settings_secondary_general_TR03_604:
           '6-weg kogelkraan wachttijd',
         // Changeover WKW
         heating_and_cooling_settings_changeover_wkw_TR03_title:
           'Instellingen 3.5 pijps systeem',
         heating_and_cooling_settings_changeover_wkw_TR03_1850:
           '3.5 pijps configuratie',
         heating_and_cooling_settings_changeover_wkw_TR03_1850_list: JSON.stringify(
           {
             list: {
               0: 'Verwarmen',
               1: 'Koelen',
             },
           }
         ),
         heating_and_cooling_settings_changeover_wkw_TR03_1851:
           'Cooling detection threshold',
         heating_and_cooling_settings_changeover_wkw_TR03_1852:
           'Heating detection threshold',
         heating_and_cooling_settings_changeover_wkw_TR03_1853:
           'Heating lowered setpoint',
         heating_and_cooling_settings_changeover_wkw_TR03_1854:
           'Lowered setpoint used',
         heating_and_cooling_settings_changeover_wkw_TR03_1854_list: JSON.stringify(
           {
             list: {
               0: 'Off',
               1: 'On',
             },
           }
         ),
         heating_and_cooling_settings_changeover_wkw_TR03_1855:
           'Predetection M2 position',
         heating_and_cooling_settings_changeover_wkw_TR03_1856:
           'Detection interval',
         heating_and_cooling_settings_changeover_wkw_TR03_1857:
           'Detection interval timer',
         heating_and_cooling_settings_changeover_wkw_TR03_1858:
           'Predetection timeout',
         heating_and_cooling_settings_changeover_wkw_TR03_1859:
           'Predetection timer',
         heating_and_cooling_settings_changeover_wkw_TR03_1860:
           'Configuration switch timer',
         heating_and_cooling_settings_changeover_wkw_TR03_1861:
           'Switch timeout',
         heating_and_cooling_settings_changeover_wkw_TR03_1862:
           'Configuration switch threshold',
         heating_and_cooling_settings_changeover_wkw_TR03_1863:
           'Boost function mode',
         heating_and_cooling_settings_changeover_wkw_TR03_1863_list: JSON.stringify(
           {
             list: {
               0: 'Uitgeschakeld',
               1: 'Ingeschakeld',
             },
           }
         ),
         heating_and_cooling_settings_changeover_wkw_TR03_1864:
           'Boost detection timer',
         heating_and_cooling_settings_changeover_wkw_TR03_1865:
           'Boost detection timeout',
         heating_and_cooling_settings_changeover_wkw_TR03_1866:
           'Maximum boost position',
         // Manual Operation
         settings_manual_operation_TR03_title: 'Handbediening',
         settings_manual_operation_TR03_700: 'Handbediening inschakelen',
         settings_manual_operation_TR03_700_list: JSON.stringify({
           list: {
             0: 'Uit',
             1: 'Aan',
           },
         }),
         settings_manual_operation_TR03_701:
           'Tapwater regelafsluiter bedienen (M1)',
         settings_manual_operation_TR03_702:
           'Verwarmen/koelen regelafsluiter bedienen (M2)',
         settings_manual_operation_TR03_703: 'Pomp',
         settings_manual_operation_TR03_703_list: JSON.stringify({
           list: {
             0: 'Uit',
             1: 'Aan',
           },
         }),
         settings_manual_operation_TR03_704: '6-weg kogelafsluiter',
         settings_manual_operation_TR03_704_list: JSON.stringify({
           list: {
             0: 'Stand: Verwarmen',
             1: 'Stand: Koelen',
           },
         }),
         settings_manual_operation_TR03_706: 'Heat demand status output',
         settings_manual_operation_TR03_706_list: JSON.stringify({
           list: {
             0: 'Heating or demand',
             1: 'Cooling',
           },
         }),
         settings_manual_operation_TR03_707:
           'Tapwater regelafsluiter bedienen (M1)[%]',
         settings_manual_operation_TR03_708:
           'Verwarmen/koelen regelafsluiter bedienen (M2)[%]',
         settings_manual_operation_TR03_1801: 'Elektisch element TE-Booster',
         settings_manual_operation_TR03_1801_list: JSON.stringify({
           list: {
             0: 'Uitgeschakeld',
             1: 'Ingeschakeld',
           },
         }),
         settings_manual_operation_TR03_709: 'Handbediening uitstellen',
         // Live Tap Water
         live_tap_water_title: 'Livelog tapwater',
         live_tap_water_101: 'Tapwater status',
         live_tap_water_107: 'Tapwater regelafsluiter (M1)',
         live_tap_water_200: 'Tapwater volumestroom',
         live_tap_water_201: 'T-Tapwater',
         live_tap_water_202: 'T-Primaire aanvoer',
         live_tap_water_203: 'T-Primaire retour',
         live_tap_water_1007: 'Primaire flow energiemeter',
         live_tap_water_1008: 'Actuele vermogen',
         // Live CV
         live_cv_title: 'Livelog verwarmen',
         live_cv_100: 'Verwarmen status',
         live_cv_108: 'Verwarmen/koelen regelafsluiter (M2)',
         live_cv_202: 'T-Primaire aanvoer',
         live_cv_203: 'T-Primaire retour',
         live_cv_204: 'Berekende aanvoertemperatuur (setpoint)',
         live_cv_205: 'T-Secundaire aanvoer',
         live_cv_206: 'T-Secundaire retour',
         live_cv_113: 'Ruimtethermostaat contact',
         live_cv_210: 'Waterdruk in de binnenhuisinstallatie',
         live_cv_1007: 'Primaire flow M-Bus meter',
         live_cv_1008: 'Actuele vermogen',
         // Live Cooling
         live_cooling_title: 'Livelog koelen',
         live_cooling_104: 'Koelen status',
         live_cooling_109: 'Pomp',
         live_cooling_110: '6-weg kogelafsluiter',
         live_cooling_113: 'Ruimtethermostaat contact',
         live_cooling_108: 'Verwarmen/koelen regelafsluiter (M2)',
         live_cooling_202: 'T-Primaire aanvoer',
         live_cooling_204: 'Berekende aanvoertemperatuur (setpoint)',
         live_cooling_205: 'T-Secundaire aanvoer',
         live_cooling_206: 'T-Secundaire retour',
         // M Bus Limit
         m_bus_limit_title: 'M-Bus Limit',
         m_bus_limit_1500: 'Begrenzing inschakelen',
         m_bus_limit_1501: 'Serienummer begrenzing meter',
         m_bus_limit_1503: 'Begrens status',
         m_bus_limit_1504: 'Begrenzing configuratie DHW (h) en CH (l)',
         m_bus_limit_1505: 'Begrens vermogen',
         m_bus_limit_1506: 'Begrens flow',
         m_bus_limit_1507: 'Begrens retour temperatuur (Alleen voor CH)',
         // Meter 1
         m_bus_meter_1_title: 'Meter 1 (Warmte)',
         m_bus_meter_1_0000: 'Serienummer',
         m_bus_meter_1_0002: 'Fabrikant',
         m_bus_meter_1_0003: 'Meter Type',
         m_bus_meter_1_0004: 'Status',
         m_bus_meter_1_0005: 'Volumestroom',
         m_bus_meter_1_0006: 'Vermogen',
         m_bus_meter_1_0007: 'Aanvoertemperatuur',
         m_bus_meter_1_0008: 'Retourtemperatuur',
         m_bus_meter_1_0010: 'Totale energieverbruik',
         m_bus_meter_1_0012: 'Totale volume',
         // Meter 2
         m_bus_meter_2_title: 'Meter 2 (Koude)',
         m_bus_meter_2_0100: 'Serienummer',
         m_bus_meter_2_0102: 'Fabrikant',
         m_bus_meter_2_0103: 'Meter Type',
         m_bus_meter_2_0104: 'Status',
         m_bus_meter_2_0105: 'Volumestroom',
         m_bus_meter_2_0106: 'Vermogen',
         m_bus_meter_2_0107: 'Aanvoertemperatuur',
         m_bus_meter_2_0108: 'Retourtemperatuur',
         m_bus_meter_2_0110: 'Totale energieverbruik',
         m_bus_meter_2_0112: 'Totaal volume',
         // Meter 3
         m_bus_meter_3_title: 'Meter 3 (Electriciteit)',
         m_bus_meter_3_0200: 'Serienummer',
         m_bus_meter_3_0202: 'Fabrikant',
         m_bus_meter_3_0203: 'Meter Type',
         m_bus_meter_3_0204: 'Status',
         m_bus_meter_3_0206: 'Power',
         m_bus_meter_3_0210: 'Energy',
         m_bus_meter_3_0214: 'Current',
         // Meter 4
         m_bus_meter_4_title: 'Meter 4 (Water)',
         m_bus_meter_4_0300: 'Serienummer',
         m_bus_meter_4_0302: 'Fabrikant',
         m_bus_meter_4_0303: 'Meter Type',
         m_bus_meter_4_0304: 'Status',
         m_bus_meter_4_0305: 'Volumestroom',
         m_bus_meter_4_0307: 'Aanvoertemperatuur',
         m_bus_meter_4_0312: 'Totaal volume',
         // Meter 5
         m_bus_meter_5_title: 'Meter 5 (Warmte/koude)',
         m_bus_meter_5_0400: 'Serienummer',
         m_bus_meter_5_0402: 'Fabrikant',
         m_bus_meter_5_0403: 'Meter Type',
         m_bus_meter_5_0404: 'Status',
         m_bus_meter_5_0405: 'Volumestroom',
         m_bus_meter_5_0406: 'Vermogen',
         m_bus_meter_5_0407: 'Aanvoertemperatuur',
         m_bus_meter_5_0408: 'Retourtemperatuur',
         m_bus_meter_5_0410: 'Totale energieverbruik (warmte)',
         m_bus_meter_5_0412: 'Volume',
         m_bus_meter_5_0414: 'Totale energieverbruik (koeling)',
         // History Block Errors
         history_block_errors_title: 'Geschiedenis van block-errors',
         history_block_errors_860: 'Block Error 1',
         history_block_errors_861: 'Block Error 2',
         history_block_errors_862: 'Block Error 3',
         history_block_errors_863: 'Block Error 4',
         history_block_errors_864: 'Block Error 5',
         history_block_errors_865: 'Block Error 6',
         history_block_errors_866: 'Block Error 7',
         history_block_errors_867: 'Block Error 8',
         history_block_errors_868: 'Block Error 9',
         history_block_errors_869: 'Block Error 10',
         history_block_errors_870: 'Block Error 11',
         history_block_errors_871: 'Block Error 12',
         history_block_errors_872: 'Block Error 13',
         history_block_errors_873: 'Block Error 14',
         history_block_errors_874: 'Block Error 15',
         // Events
         device_events_type_filter_label: 'Event Type',
         device_events_type_filter_value_1: 'Audit',
         device_events_type_filter_value_2: 'Error',
         // Presets
         presets: 'Presets',
         presets_all: 'ALLE',
         presets_active: 'ACTIEF',
         presets_inactive: 'INACTIEF',
         presets_add_new: 'Toevoegen',
         presets_filter_search: 'Zoeken',
         presets_filter_reset_button: 'Reset',
         presets_filter_delete_button: 'Verwijderen',
         presets_table_name: 'Naam',
         presets_table_status: 'Status',
         presets_updated_date: 'Gewijzigd op',
         presets_action: 'Actions',
         presets_table_row_per_page: 'Resultaten per pagina',
         presets_status_menu_list: JSON.stringify([
           {
             key: 'settings_hot_water',
             value: 'Instellingen tapwater',
             permission: 'settings-hot-water-tab-read',
           },
           {
             key: 'settings_heating_and_cooling',
             value: 'Instellingen verwarmen en koelen',
             permission: 'settings-heating-and-cooling-tab-read',
           },
           {
             key: 'manual_control',
             value: 'Handbediening',
             permission: 'manual-control-tab-read',
           },
         ]),
         add_presets_title: 'Preset toevoegen',
         edit_presets_title: 'Preset wijzigen',
         presets_form_name_required: 'Naam van de preset is verplicht',
         presets_form_register_required: 'Register waarde is verplicht',
         presets_form_add_preset_button: 'Wijzigingen opslaan',
         presets_form_edit_preset_button: 'Wijzigingen opslaan',
         presets_form_cancel_button: 'Annuleer',
         presets_add_form_success: 'Preset is succesvol aangemaakt!',
         presets_edit_form_success: 'Preset is succesvol gewijzigd!',
         presets_form_api_error: 'Internal Server Error',
         presets_no_record_found: 'Geen presets gevonden',
         presets_deactivated_message: 'Preset is succesvol gedeactiveerd',
         presets_activated_message: 'Preset Activated successfully',
         presets_bulk_no_user_is_selected_error_message:
           'Selecteer records om te deactiveren',
         presets_bulk_deactivated_message:
           '{{ USERS_COUNT }} Preset(s) zijn succesvol gedeactiveerd',
         // Audits
         audits: 'Logboek handelingen',
         audits_all: 'Alle',
         audits_active: 'Audit',
         audits_inactive: 'Storingen',
         audits_filter_search: 'Zoeken',
         audits_client_filter_label: 'Klant',
         audits_error_code_filter_label: 'Storing',
         audits_project_filter_label: 'Project',
         audits_order_number_filter_label: 'Ordernummer',
         audits_events_type_filter_label: 'Event Type',
         audits_date_range_filter_label: 'Bereik',
         audits_events_type_filter_value_1: 'Audit',
         audits_events_type_filter_value_2: 'Alarm',
         audits_reset_filters_button: 'Reset',
         audits_date: 'Datum',
         audits_device: 'Device',
         audits_client: 'Klant',
         audits_message: 'Bericht',
         audits_table_row_per_page: 'Resultaten per pagina',
         audits_table_no_record_found: 'Geen devices gevonden',
         // Settings
         settings_update_profile_title: 'Wijzig profiel',
         settings_notification_title: 'Notificaties',
         settings_notification_window_exceptions_title:
           'Notify when operating window exceptions occur',
         settings_notification_device_error_title:
           'Melding wanneer een apparaat zich in de storingsmodus bevindt',
         settings_notification_channels_title: 'Meldingsmogelijkheden',
         settings_notification_use_email_title: 'Email',
         /* Admins */
         admins: 'Admins',
         admins_first_bread_crumb: 'Fortes administrators',
         admins_filter_search: 'Administrator zoeken',
         admins_add_new: 'Adminstrator toevoegen',
         admins_filter_reset_button: 'Reset',
         admins_filter_delete_button: 'Deactiveren',
         admins_search: 'Zoeken',
         admins_all: 'ALLE',
         admins_active: 'ACTIEF',
         admins_inactive: 'INACTIEF',
         admins_table_name: 'Naam',
         admins_table_address: 'Adres',
         admins_table_role_id: 'Rol',
         admins_table_status: 'Status',
         admins_table_added: 'Toegevoegd',
         admins_table_no_record_found: 'Geen device gevonden',
         admins_table_row_per_page: 'Resultaten per pagina',
         admins_no_user_selected_for_de_activation_message:
           'Selecteer records om te deactiveren',
         admins_bread_crumb_main_title: 'Admins',
         add_admins_bread_crumb_first_link: 'Admin toevoegen',
         add_admins_form_header: 'Admin toevoegen',
         edit_admins_bread_crumb_first_link: 'Admin wijzigen',
         edit_admins_form_header: 'Admin wijzigen',
         admins_form_first_name_field: 'Voornaam',
         admins_form_last_name_field: 'Achternaam',
         admins_form_email_field: 'Email adres',
         admins_form_official_email_field: 'Email adres',
         admins_form_phone_field: 'Telefoonnummer',
         admins_form_official_phone_number_field: 'Telefoon nummer',
         admins_form_password_field: 'Wachtwoord',
         admins_form_role_id_field: 'Rol',
         admins_form_select_role_id_field: 'Selecteer rol',
         admins_form_street_number_field: 'Straat',
         admins_form_house_number_field: 'Huisnummer',
         admins_form_neighbourhood_field: 'Neighbourhood',
         admins_form_postal_code_field: 'Postcode',
         admins_form_city_field: 'Stad',
         admins_form_country_field: 'Land',
         admins_form_email_verification_field: 'Email verificatie',
         admins_form_email_verification_sub_title_field:
           'Als u dit uitschakelt, wordt de beheerder automatisch een verificatie email gestuurd',
         admins_form_add_admin_button: 'Wijzigingen opslaan',
         admins_form_edit_admin_button: 'Wijzigingen opslaan',
         admins_form_cancel_button: 'Annuleer',
         admins_form_submitting_admin_button: 'Verwerken...',
         admins_first_name_required_form_error: 'Voornaam is verplicht',
         admins_last_name_required_form_error: 'Achternaam is verplicht',
         admins_email_required_form_error: 'Email is verplicht',
         admins_email_valid_form_error: '',
         admins_password_required_form_error: 'Wachtwoord is verplicht',
         admins_country_required_form_error: 'Land is verplicht',
         admins_role_id_required_form_error: 'Rol is verplicht',
         admins_password_form_error:
           'Wachtwoord moet minimaal 8 tekens bevatten met tenminste één hoofdletter, één cijfer en één speciaal teken.',
         admins_add_form_success: 'Administrator is succesvol aangemaakt!',
         admins_edit_form_success: 'Administrator is succesvol gewijzigd!',
         admins_form_api_error: 'Internal Server Error',
         admins_form_email_error: 'Email adres bestaat al',
         admins_no_record_found: 'Geen administrator gevonden',
         admins_deactivated_message: 'Admin is succesvol gedeactiveerd',
         admins_activated_message: 'Administrator is sucesvol geactiveerd',
         admins_email_verified_title: 'Email bevestigd',
         admins_email_verified_sub_title:
           'Als u dit uitschakelt, wordt de beheerder automatisch een verificatie-e-mail gestuurd',
         admins_bulk_no_admin_is_selected_error_message:
           'Selecteer records om te deactiveren',
         admins_bulk_deactivated_message:
           '{{ USERS_COUNT }} Admin(s) zijn succesvol gedeactiveerd',
         // Zoeken Results
         search_result_title: 'Zoek resultaten: ',
         search_users: 'Gebruikers',
         search_clients: 'Klanten',
         search_service_clients: 'Service klanten',
         search_devices: 'Devices',
         search_presets: 'Presets',
         search_projects: 'Projecten',
         // New Locales
         no_error_devices_found: 'Geen devices met storingen gevonden!',
         project_single_first_bread_crumb: 'Projecten',
         project_single_second_bread_crumb: 'Project weergeven',
         project_single_client_label: 'Klant',
         project_single_service_client_label: 'Service klant',
         project_single_devices_label: 'Devices',
         project_single_edit_button: 'Wijzigen',
         project_single_devices_tab: 'Devices',
         client_single_view_more_errors_button: 'Toon meer',
         client_error_single_devices_tab: 'Alarm devices',
         client_error_single_device_name: 'Device',
         client_error_single_device_error_code: 'Alarm code',
         client_error_single_device_error_date_time: 'Datum',
         devices_bulk_edit_registers_button: 'Instelling(en) wijzigen',
         devices_bulk_edit_ota_button: 'Device(s) updaten',
         client_error_single_device_errors_table_row_per_page:
           'Rijen per pagina',
         client_error_single_device_error_no_record_found:
           'Geen devices gevonden',
         devices_bulk_edit_registers_title: 'Device registers groepswijziging',
         devices_bulk_edit_registers_form_submit_button: 'Bulk update',
         devices_bulk_edit_registers_form_cancel_button: 'Annuleer',
         devices_bulk_edit_registers_form_success:
           'Bericht wordt doorgestuurd naar geselecteerde apparaat(en)',
         devices_bulk_edit_registers_form_error:
           'Gelieve ten minste één registerwaarde in te vullen',
         devices_bulk_edit_registers_form_api_error: 'Internal Server Error',
         devices_bulk_edit_ota_title: 'Device OTA groep Update',
         devices_bulk_edit_ota_form_firmware_field: 'Firmware',
         devices_bulk_edit_ota_form_submit_button: 'Groepsupdate',
         devices_bulk_edit_ota_form_cancel_button: 'Annuleer',
         devices_bulk_edit_ota_form_success:
           'Bericht wordt doorgestuurd naar geselecteerde apparaat(en)',
         devices_bulk_edit_ota_form_firmware_required_error:
           'Firmware is verplicht',
         devices_bulk_edit_ota_form_api_error: 'Internal Server Error',
         devices_bulk_edit_registers_selected_devices:
           'Apparaten geselecteerd voor groepsbewerking',
         // Device Status Table Pop Over
         custom_popover_min_value: 'Min waarde',
         custom_popover_max_value: 'Max waarde',
         custom_popover_list_options: 'Mogelijke waarden',
         custom_popover_short_description: 'Omschrijving',
         custom_popover_close_modal_button: 'Sluiten',
         // Warmtapwater PopOver Short Omschrijving
         custom_popover_300_short_description: 'DHW Setpoint',
         custom_popover_301_short_description: 'Preheat mode',
         custom_popover_302_short_description: 'Comfort Setpoint',
         custom_popover_303_short_description: 'Flow rate start threshold',
         custom_popover_304_short_description: 'DHW Enable',
         // Thermal disinfection PopOver Short Omschrijving
         custom_popover_400_short_description: 'Aleg Enabled',
         custom_popover_401_short_description: 'Aleg Setpoint',
         custom_popover_402_short_description: 'Aleg Burn Time',
         custom_popover_403_short_description: 'Aleg Wait Time',
         // TE-Booster PopOver Short Omschrijving
         custom_popover_1800_short_description: 'TE-Booster Enabled',
         custom_popover_1804_short_description: 'TE-Booster Setpoint',
         custom_popover_1805_short_description:
           'TE-Booster Setpoint Hysteresis',
         custom_popover_1812_short_description: 'TE-Booster Setpoint Timeout',
         custom_popover_1813_short_description: 'TE-Booster Setpoint Timer',
         custom_popover_1815_short_description: 'TE-Booster Threshold',
         custom_popover_1816_short_description: 'TE-Booster Taped Volume',
         // Heating PopOver Short Omschrijving
         custom_popover_500_short_description: 'CH Max Setpoint',
         custom_popover_501_short_description: 'CH Min Setpoint',
         custom_popover_506_short_description: 'Max Primary Return',
         custom_popover_507_short_description: 'Max Primary Return Percentage',
         custom_popover_508_short_description: 'Delta Prim CH Setpoint',
         custom_popover_510_short_description: 'Max Heating Temperature',
         custom_popover_509_short_description: 'Delta Prim Min',
         custom_popover_514_short_description: 'CH Setpoint',
         custom_popover_515_short_description: 'CH Enable',
         // Cooling PopOver Short Omschrijving
         custom_popover_600_short_description: 'CoolTempSetpoint',
         custom_popover_601_short_description: 'Cool MaxPrimary Return',
         custom_popover_602_short_description:
           'Cool % of Max Setpoint compensation',
         custom_popover_605_short_description: 'Max Cooling Temp',
         custom_popover_606_short_description: 'Cool setpoint min',
         custom_popover_607_short_description: 'Cool setpoint max',
         custom_popover_611_short_description: 'Cooling Enable',
         // Secondary General PopOver Short Omschrijving
         custom_popover_512_short_description: 'CH Min Pressure',
         custom_popover_513_short_description: 'CH Max Pressure',
         custom_popover_603_short_description: 'CoolHeatWaitingTime',
         custom_popover_604_short_description: '6way valve waiting time',
         // Changeover WKW PopOver Short Omschrijving
         custom_popover_1850_short_description: '3.5Pipe Configuration',
         custom_popover_1851_short_description: 'Cooling detection threshold',
         custom_popover_1852_short_description: 'Heating detection threshold',
         custom_popover_1853_short_description: 'Heating lowered setpoint',
         custom_popover_1854_short_description: 'Lowered setpoint used',
         custom_popover_1855_short_description: 'Predetection MCV2 position',
         custom_popover_1856_short_description: 'Detection interval',
         custom_popover_1857_short_description: 'Detection interval timer',
         custom_popover_1858_short_description: 'Predetection timeout',
         custom_popover_1859_short_description: 'Predetection timer',
         custom_popover_1860_short_description: 'Configuration switch timer',
         custom_popover_1861_short_description: 'Switch timeout',
         custom_popover_1862_short_description:
           'Configuration switch threshold',
         custom_popover_1863_short_description: 'Boost function mode',
         custom_popover_1864_short_description: 'Boost detection timer',
         custom_popover_1865_short_description: 'Boost detection timeout',
         custom_popover_1866_short_description: 'Maximum boost position',
         // Manual Operation PopOver Short Omschrijving
         custom_popover_700_short_description: 'Manual Control enabled',
         custom_popover_701_short_description: 'MCV-1 Manual control',
         custom_popover_702_short_description: 'MCV-2 Manual control',
         custom_popover_703_short_description: 'Pump (P1) Manual control',
         custom_popover_704_short_description: 'Valve1',
         custom_popover_706_short_description: 'Heat demand status output',
         custom_popover_707_short_description: 'MCV-1 Manual control[%]',
         custom_popover_708_short_description: 'MCV-1 Manual control[%]',
         custom_popover_1801_short_description: 'Elektisch element TE-Booster',
         custom_popover_709_short_description: 'Manual control timeout',
         // Warmtapwater PopOver Long Omschrijving
         custom_popover_300_long_description: 'DHW Setpoint',
         custom_popover_301_long_description: 'Preheat mode',
         custom_popover_302_long_description: 'Comfort Setpoint',
         custom_popover_303_long_description: 'Flow rate start threshold',
         custom_popover_304_long_description: 'DHW Enable',
         // Thermal disinfection PopOver Long Omschrijving
         custom_popover_400_long_description: 'Aleg Enabled',
         custom_popover_401_long_description: 'Aleg Setpoint',
         custom_popover_402_long_description: 'Aleg Burn Time',
         custom_popover_403_long_description: 'Aleg Wait Time',
         // TE-Booster PopOver Long Omschrijving
         custom_popover_1800_long_description: 'TE-Booster Enabled',
         custom_popover_1804_long_description: 'TE-Booster Setpoint',
         custom_popover_1805_long_description: 'TE-Booster Setpoint Hysteresis',
         custom_popover_1812_long_description: 'TE-Booster Setpoint Timeout',
         custom_popover_1813_long_description: 'TE-Booster Setpoint Timer',
         custom_popover_1815_long_description: 'TE-Booster Threshold',
         custom_popover_1816_long_description: 'TE-Booster Taped Volume',
         // Heating PopOver Long Omschrijving
         custom_popover_500_long_description: 'CH Max Setpoint',
         custom_popover_501_long_description: 'CH Min Setpoint',
         custom_popover_506_long_description: 'Max Primary Return',
         custom_popover_507_long_description: 'Max Primary Return Percentage',
         custom_popover_508_long_description: 'Delta Prim CH Setpoint',
         custom_popover_510_long_description: 'Max Heating Temperature',
         custom_popover_509_long_description: 'Delta Prim Min',
         custom_popover_514_long_description: 'CH Setpoint',
         custom_popover_515_long_description: 'CH Enable',
         // Cooling PopOver Long Omschrijving
         custom_popover_600_long_description: 'CoolTempSetpoint',
         custom_popover_601_long_description: 'Cool MaxPrimary Return',
         custom_popover_602_long_description:
           'Cool % of Max Setpoint compensation',
         custom_popover_605_long_description: 'Max Cooling Temp',
         custom_popover_606_long_description: 'Cool setpoint min',
         custom_popover_607_long_description: 'Cool setpoint max',
         custom_popover_611_long_description: 'Cooling Enable',
         // Secondary General PopOver Long Omschrijving
         custom_popover_512_long_description: 'CH Min Pressure',
         custom_popover_513_long_description: 'CH Max Pressure',
         custom_popover_603_long_description: 'CoolHeatWaitingTime',
         custom_popover_604_long_description: '6way valve waiting time',
         // Changeover WKW PopOver Long Omschrijving
         custom_popover_1850_long_description: '3.5Pipe Configuration',
         custom_popover_1851_long_description: 'Cooling detection threshold',
         custom_popover_1852_long_description: 'Heating detection threshold',
         custom_popover_1853_long_description: 'Heating lowered setpoint',
         custom_popover_1854_long_description: 'Lowered setpoint used',
         custom_popover_1855_long_description: 'Predetection MCV2 position',
         custom_popover_1856_long_description: 'Detection interval',
         custom_popover_1857_long_description: 'Detection interval timer',
         custom_popover_1858_long_description: 'Predetection timeout',
         custom_popover_1859_long_description: 'Predetection timer',
         custom_popover_1860_long_description: 'Configuration switch timer',
         custom_popover_1861_long_description: 'Switch timeout',
         custom_popover_1862_long_description: 'Configuration switch threshold',
         custom_popover_1863_long_description: 'Boost function mode',
         custom_popover_1864_long_description: 'Boost detection timer',
         custom_popover_1865_long_description: 'Boost detection timeout',
         custom_popover_1866_long_description: 'Maximum boost position',
         // Manual Operation PopOver Long Omschrijving
         custom_popover_700_long_description: 'Manual Control enabled',
         custom_popover_701_long_description: 'MCV-1 Manual control',
         custom_popover_702_long_description: 'MCV-2 Manual control',
         custom_popover_703_long_description: 'Pump (P1) Manual control',
         custom_popover_704_long_description: 'Valve1',
         custom_popover_706_long_description: 'Heat demand status output',
         custom_popover_707_long_description: 'MCV-1 Manual control[%]',
         custom_popover_708_long_description: 'MCV-1 Manual control[%]',
         custom_popover_1801_long_description: 'Elektisch element TE-Booster',
         custom_popover_709_long_description: 'Manual control timeout',
         // API keys
         client_single_api_key_approve_api_key_label: 'API Key',
         client_single_api_key_app_id_label: 'APP Id',
         client_single_api_key_approve_api_key_button: 'Account toestaan',
         client_single_api_key_suspend_api_key_button: 'Opschorten API toegang',
         client_single_api_key_unblock_application_button: 'API deblokkeren',
         client_single_api_key_generate_api_key_button: 'API Key genereren',
         client_single_api_key_generate_api_key_success:
           'API Key is succesvol gegenereerd',
         client_single_api_key_generate_api_limit_error:
           'Sorry, we kunnen geen nieuwe API-sleutel opnieuw genereren omdat de limiet voor opnieuw genereren is bereikt.',
         client_single_api_key_generate_api_3scale_error:
           '3Scale API Error. Neem contact op met de beheerder.',
         client_single_api_application_suspend_success:
           '3Scale Application is suspended successfully',
         client_single_api_application_unblock_success:
           '3Scale Application is Unblocked successfully',
         client_single_api_application_suspend_error:
           'Application is already suspended',
         client_single_api_application_unblock_errpr:
           'Application is already unblocked',
         client_single_api_key_account_pending_approval:
           'Uw account is nog niet goedgekeurd. Neem contact op met de beheerder.',
         client_single_api_key_account_suspended:
           'Your account is suspended. Please contact Administrator for more details.',
         // Clients Form
         three_scale_temporary_password_subject:
           'Fortes Data Management - 3Scale Account Created',
         clients_form_password_field: 'Wachtwoord',
         clients_password_required_form_error:
           'Password must contain atleast one alphabet of uppercase, number and special character',
         // Devices Form
         devices_form_mbus_meters_are_not_connected:
           'MBus Meters are not connected.',
         MBUS_METER_HEATING: 'Warmte meter',
         MBUS_METER_COOLING: 'Koude meter',
         MBUS_METER_ELECTRICITY: 'Electriciteit meter',
         MBUS_METER_WATER: 'Water meter',
         MBUS_METER_BI_DIRECTIONAL: 'Warmte/koude meter',
         meter_enabled: 'Ingeschakeld',
         meter_disabled: 'Uitgeschakeld',
         devices_mbus_registers_toggle_register_status_success:
           'The message has been successfully sent to device.',
         MBUS_METER_SERIAL_NUMBER: 'Serienummer',
         MBUS_METER_MANUFACTURER: 'Fabrikant',
         MBUS_METER_METER_TYPE: 'Meter type',
         MBUS_METER_STATUS: 'Status',
         MBUS_METER_FLOW: 'Volumestroom',
         MBUS_METER_POWER: 'Vermogen',
         MBUS_METER_SUPPLY_TEMPERATURE: 'Aanvoertemperatuur',
         MBUS_METER_RETURN_TEMPERATURE: 'Retourtemperatuur',
         MBUS_METER_HEATING_ENERGY: 'Totale energieverbruik (warmte)',
         MBUS_METER_ELECTRICITY_ENERGY:
           'Totale energieverbruik (electriciteit)',
         MBUS_METER_VOLUME: 'Totale volume',
         MBUS_METER_COOLING_ENERGY: 'Totale energieverbruik (koude)',
         MBUS_METER_CURRENT: 'Current',
         custom_popover_MBUS_METER_SERIAL_NUMBER_short_description:
           'Serienummer',
         custom_popover_MBUS_METER_MANUFACTURER_short_description: 'Fabrikant',
         custom_popover_MBUS_METER_METER_TYPE_short_description: 'Meter type',
         custom_popover_MBUS_METER_STATUS_short_description: 'Status',
         custom_popover_MBUS_METER_FLOW_short_description: 'Volumestroom',
         custom_popover_MBUS_METER_POWER_short_description: 'Vermogen',
         custom_popover_MBUS_METER_SUPPLY_TEMPERATURE_short_description:
           'Aanvoertemperatuur',
         custom_popover_MBUS_METER_RETURN_TEMPERATURE_short_description:
           'Retourtemperatuur',
         custom_popover_MBUS_METER_HEATING_ENERGY_short_description:
           'Totale energieverbruik (warmte)',
         custom_popover_MBUS_METER_ELECTRICITY_ENERGY:
           'Totale energieverbruik (electriciteit)',
         custom_popover_MBUS_METER_VOLUME_short_description: 'Totale volume',
         custom_popover_MBUS_METER_COOLING_ENERGY_short_description:
           'Totale energieverbruik (koude)',
         custom_popover_MBUS_METER_CURRENT_short_description: 'Current',
         // Device Set Registers Per Device
         device_single_device_registers_tab: 'Set log',
         device_single_device_registers_menu_list: JSON.stringify([
           {
             key: 'operating_state',
             value: 'Bedrijfsstatus',
             permission: 'operating-state-tab-read',
           },
         ]),
         device_status_interval: 'Interval',
         device_status_interval_in_seconds: 'Interval in seconden',
         device_single_set_registers_error:
           'Selecteer tenminste 1 register om door te gaan.',
         device_single_set_registers_success:
           'Bericht is succesvol doorgestuurd naar het device.',
         device_single_set_register_form_interval_required_error:
           'Interval (seconden) is vereist',
         device_single_set_register_form_interval_numeric_error:
           'Interval (seconden) moet een getal zijn',
         device_single_set_register_form_send_button: 'Instellen',
         device_single_set_register_form_cancel_button: 'Annuleren',
         clients_3scale_email_error: 'Email bestaal al in 3Scale',
         page_title_appending_title: ' | Fortes Data Management',
         project_single_device_registers_tab: 'Set log',
         audits_user: 'Gebruiker',
         audits_system_generated: 'Applicatie',
         device_single_project_set_registers_error:
           'Bericht wordt niet doorgestuurd omdat er geen devices aan het project zijn gekoppeld',
         audits_user_filter_label: 'Gebruiker',
         audits_event_type_field: 'Event type',
         audits_select_event_type_field: 'Selecteer event type',
         audits_select_event_type_incoming_option: 'Inkomend',
         audits_select_event_type_outgoing_option: 'Uitgaand',
         audits_select_event_type_system_generated_option: 'Applicatie',
         audits_table_loading: 'Events laden',
         appliance_type_reference_table_appliance_name: 'Naam',
         appliance_type_reference_table_appliance_type: 'Toesteltype',
         appliance_type_reference_table_updated_at: 'Gewijzigd op',
         sidebar_appliance_type_reference_menu: 'Toesteltype',
         appliance_types_register_form_send_button: 'Opslaan',
         appliance_types_register_form_cancel_button: 'Annuleer',
         appliance_types: 'Toesteltypes',
         appliance_types_table_no_record_found: 'Geen device gevonden',
         appliance_types_table_row_per_page: 'Resultaten per pagina',
         add_appliance_type: 'Toesteltype toevoegen',
         add_appliance_types_form_header: 'Toestel type toevoegen',
         edit_appliance_types_form_header: 'Toesteltype wijzigen',
         appliance_types_add_new: 'Toevoegen',
         appliance_type_form_name_label: 'Naam',
         appliance_type_form_type_label: 'Toesteltype',
         appliance_type_form_select_type_label: 'Selecteer Toesteltype',
         appliance_name_form_required_error: 'Naam is verplicht',
         appliance_type_form_required_error: 'Toesteltype is verplicht',
         add_appliance_type_form_success:
           'Toesteltype record is succesvol aangemaakt',
         add_appliance_type_form_api_error:
           'Internal Server add toesteltype - API Error',
         edit_appliance_type_form_success:
           'Toesteltype record is succesvol gewijzigd',
         edit_appliance_type_form_api_error:
           'Internal Server edit toesteltype - API Error',
         appliance_type_reference_table_filter_reset_button: 'Reset',
         appliance_type_reference_table_search: 'Zoeken',
         appliance_type_reference_status_menu_list: JSON.stringify([
           {
             key: 'operating_state',
             value: 'Bedrijfsstatus',
             permission: 'operating-state-tab-read',
           },
           {
             key: 'settings_hot_water',
             value: 'Instellingen tapwater',
             permission: 'settings-hot-water-tab-read',
           },
           {
             key: 'settings_heating_and_cooling',
             value: 'Instellingen verwarmen en koelen',
             permission: 'settings-heating-and-cooling-tab-read',
           },
           {
             key: 'manual_control',
             value: 'Handbediening',
             permission: 'manual-control-tab-read',
           },
         ]),
         audits_filter_button: 'Filters',
         device_single_no_registers_are_available:
           'Registers zijn niet beschikbaar',
         device_single_device_diagnostics_tab: 'Diagnostics',
         device_single_dp_measurement_back_button: 'Vorige',
         device_single_diagnostic_next_button: 'Volgende',
         device_single_dp_measurement_start_button: 'Start',
         device_single_diagnostict_finish_button: 'Finish',
         device_single_diagnostic_stop_button: 'Stop',
         device_single_dp_measurement_refresh_button: 'Verversen',
         device_single_dp_measurement_event_logs_title: 'Uitkomsten',
         device_single_dp_measurement_select_mbus_meter_button:
           'Selecteer M-Bus Meter',
         device_single_dp_measurement_step_1_title:
           'Drukverschil berekening - Kies flow',
         device_single_dp_measurement_pressure_difference_stepper_1_title:
           'Kies flow',
         device_single_dp_measurement_step_1_flow_1_label:
           'Drukverschil berekenen',
         device_single_dp_measurement_step_1_flow_2_label: 'Warmwater',
         device_single_dp_measurement_pressure_difference_step_2_title:
           'Drukverschil berekening - Uitleg van de wizard',
         device_single_dp_measurement_pressure_difference_stepper_2_title:
           'Uitleg van de wizard',
         device_single_dp_measurement_pressure_difference_step_2_description:
           'Beschikbaar drukverschil is altijd een vraagstuk bij collectieve netwerken. Met de krachtige combinatie van AquaHeat aflversets en het Fortes Data Management platform kunt u middels een gebruiksvriendelijke module eenvoudig een drukverschilberekening uitvoeren op ieder punt in uw collectieve netwerk. Dit geeft een uitstekende eerste indicatie van een mogelijke oorzaak. Middels deze diagnose stellen wij u in staat om een eerste indicatie van het beschikbare drukverschil te laten berekenen. <br /><br /><strong>Vereist: Warmtemeter dient verbonden te zijn aan de Mercurius Communicatie Module</strong>',
         device_single_dp_measurement_pressure_difference_step_3_title:
           'Drukverschil berekening - Aansturen van de juiste componenten',
         device_single_dp_measurement_pressure_difference_stepper_3_title:
           'Handbediening register',
         device_single_dp_measurement_step_3_pressure_difference_output_1:
           'Uitkomst 1 - Aanzetten van de handbediening',
         device_single_dp_measurement_pressure_difference_output_2:
           'Uitkomst 2 - Openen van de regelafsluiter',
         device_single_dp_measurement_pressure_difference_step_3_mbus_meter_title:
           'Selecteer M-Bus Meter (bij meerdere meters betreft het de energiemeter welke het tapwater registreert.)',
         device_single_dp_measurement_pressure_difference_stepper_4_title:
           'M-Bus Meter selecteren',
         device_single_dp_measurement_pressure_difference_step_4_flow_label:
           'Volumestroom',
         device_single_dp_measurement_pressure_difference_stepper_5_title:
           'Berekenen van het drukverschil',
         device_single_dp_measurement_pressure_difference_output_3:
           'Uitkomst 3 - Selecteer Meter',
         device_single_dp_measurement_pressure_difference_output_4:
           'Uitkomst 4 - Calculation of the differential pressure',
         device_single_dp_measurement_pressure_difference_stepper_6_title:
           'Resultaat',
         device_single_dp_measurement_pressure_difference_step_6_description:
           'Uw drukverschil is OK! Er zijn geen verdere acties vereist. ',
         device_single_dp_measurement_download_button: 'Download resultaten',
         device_single_dp_measurement_pressure_difference_step_7_description:
           'De drukverschil berekening is succesvol afgerond.',
         device_single_general_title: 'Algemeen',
         device_single_hotwater_title: 'Warmwater',
         device_single_dp_measurement_hot_water_flow_1_stepper_1_title:
           'Kies flow',
         device_single_dp_measurement_hot_water_flow_1_stepper_2_title:
           'Uitleg van de wizard',
         device_single_dp_measurement_hot_water_flow_1_stepper_3_title:
           'Open Faucet',
         device_single_dp_measurement_hot_water_flow_1_stepper_4_title:
           'Checking Faucet and Hot water valve',
         device_single_dp_measurement_hot_water_flow_1_stepper_5_title:
           'MBus Meter',
         device_single_dp_measurement_hot_water_flow_1_stepper_6_title:
           'Calculation of Hot Water Flow',
         device_single_dp_measurement_hot_water_flow_1_stepper_7_title:
           'Results',
         device_single_dp_measurement_hot_water_flow_1_step_2_title:
           'DP Measurement - Explanation of Flow',
         device_single_dp_measurement_hot_water_flow_1_step_2_description:
           'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eu proin amet vitae etiam. In mauris habitasse turpis quis tortor, integer magna dictum condimentum. In pellentesque proin eu ut viverra quam. Consequat pharetra proin lacus, ultrices enim. Tincidunt porta tortor lacinia varius mattis pellentesque. In augue sit vitae leo sed. Nibh sed mi bibendum convallis viverra vestibulum pretium commodo. Nibh quam vulputate malesuada sodales. Curabitur nisl leo quis sed vitae cras. Tempor risus, a volutpat consequat eget faucibus nulla fermentum. Ut dignissim proin nulla et congue mattis. Euismod et ipsum quis habitant in enim viverra. Mattis varius quis arcu cras vestibulum nunc mattis consectetur in. Tempus mauris egestas elit malesuada ullamcorper aliquet cras adipiscing ',
         device_single_dp_measurement_hot_water_flow_1_step_3_title:
           'Open Faucet',
         device_single_dp_measurement_hot_water_flow_1_step_3_faucet_error:
           'Flow Sensor Defect',
         device_single_dp_measurement_hot_water_flow_1_step_3_faucet_error_one_try_left:
           'Try one more time',
         diagnostic_flows_form_open_faucet_title: 'Faucet is opened by client',
         diagnostic_flows_form_open_faucet_off_option_description:
           'Faucet is not opened by client',
         device_single_dp_measurement_hot_water_flow_1_output_1:
           'Output 1 - Hot Water State',
         device_single_dp_measurement_hot_water_flow_1_output_2:
           'Output 2 - Hot water valve (M1)',
         device_single_dp_measurement_hot_water_flow_1_step_3_mbus_meter_title:
           'Select MBus Meter',
         device_single_dp_measurement_hot_water_flow_1_step_4_flow_label:
           'Flow',
         device_single_dp_measurement_step_4_hot_water_flow_1_output_3:
           'Output 3 - Select Meter',
         device_single_dp_measurement_hot_water_flow_1_output_4:
           'Output 4 - Mbus Flow',
         device_single_dp_measurement_hot_water_flow_1_output_5:
           'Output 5 - Calculation of Hot Water Flow',
         device_single_dp_measurement_hot_water_flow_1_step_7_description:
           'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eu proin amet vitae etiam. In mauris habitasse turpis quis tortor, integer magna dictum condimentum. In pellentesque proin eu ut viverra quam. Consequat pharetra proin lacus, ultrices enim. Tincidunt porta tortor lacinia varius mattis pellentesque. In augue sit vitae leo sed. Nibh sed mi bibendum convallis viverra vestibulum pretium commodo. Nibh quam vulputate malesuada sodales. Curabitur nisl leo quis sed vitae cras. Tempor risus, a volutpat consequat eget faucibus nulla fermentum. Ut dignissim proin nulla et congue mattis. Euismod et ipsum quis habitant in enim viverra. Mattis varius quis arcu cras vestibulum nunc mattis consectetur in. Tempus mauris egestas elit malesuada ullamcorper aliquet cras adipiscing ',
         device_single_dp_measurement_hot_water_flow_1_step_8_description:
           'Hot Water - Flow 1 Diagnostics is finished',
         device_single_heating_title: 'Verwarming',
         device_single_dp_measurement_step_1_flow_3_label: 'Flow 1',
         device_single_cooling_title: 'Koeling',
         device_single_dp_measurement_step_1_flow_4_label: 'Flow 1',
         device_single_temperature_graph_loading_data: 'Laden van de grafiek',
         device_single_temperature_no_graph_data_error:
           'De grafiek is niet beschikbaar',
         device_graph_week_filter: 'W',
         device_graph_weeks_filter: 'W',
         device_graph_day_filter: 'D',
         device_graph_days_filter: 'D',
         device_graph_hours_filter: 'H',
         device_single_registers_table_numeric_error:
           'Alleen numerieke waarden zijn toegestaan',
         device_single_registers_table_min_max_error:
           'Waarde moet tussen minimale en maximale waarde liggen',
         MBUS_METER_TYPE_WATER: 'Water',
         MBUS_METER_TYPE_ELECTRICITY: 'Electriciteit',
         MBUS_METER_TYPE_HEATING: 'Warmte',
         MBUS_METER_TYPE_BIDIRECTIONAL: 'Warmte/koude',
         MBUS_METER_TYPE_COOLING: 'Koude',
         UNKNOWN_MANUFACTURER: 'Onbekende fabrikant',
         commissoning_form_header: 'Inbedrijfstelling',
         commissoning_form_serial_number_scan_button: 'Scan met camera',
         commissoning_form_heat_interface_unit_scan_button:
           'Scan Heat Interface Unit',
         commissoning_device_submit_button: 'Gereed',
         commissoning_form_search_field: 'Serienummer Mercurius',
         sidebar_commissioning_overview: 'Inbedrijfstelling',
         sidebar_commissioning: 'Inbedrijfstelling',
         commissoning_form_success: 'De inbedrijfstelling is succesvol!',
         commissoning_error_message: 'Device is niet gevonden',
         swapping_form_header: 'Uitwisselen device',
         swapping_old_device_title: 'Oude device (serienummer)',
         swapping_new_device_title: 'Nieuwe device (serienummer)',
         swapping_form_scan_button: 'Scan',
         swapping_form_search_field: 'Serienummer Mercurius',
         sidebar_swapping_overview: 'Inbedrijfstelling',
         sidebar_swapping: 'Uitwisseling',
         swapping_device_submit_button: 'Gereed',
         swapping_form_success: 'Device is succesvol bijgewerkt!',
         swapping_old_device_error_message: 'Oude device is niet gevonden',
         swapping_new_device_error_message: 'Nieuwe device is niet gevonden',
         email_device_swapped_by: 'Device uitgewisseld door:',
         swapping_device_unique_error: 'Device bestaat reeds',
         clear_device_form_header: 'Wissen',
         clear_device_form_scan_button: 'Scan',
         clear_device_form_search_field: 'Serienummer Mercurius',
         sidebar_clear_device_overview: 'Clear device',
         sidebar_clear_device: 'Wissen',
         clear_device_form_success: 'Device is updated successfully!',
         clear_device_error_message: 'Device is niet gevonden',
         clear_device_submit_button: 'Clear device',
         clear_device_audit_message: 'Device is gewist',
         device_single_swapped_devices_tab: 'Uitgewisselde devices',
         devices_single_swapped_device_name: 'Device naam',
         devices_single_swapped_device_firmware: 'Firmware',
         devices_single_swapped_device_type: 'Device type',
         devices_single_swapped_device_box_serial_number:
           'Serienummer afleverset',
         devices_single_swapped_device_sim_card_number: 'Simkaart nummer',
         devices_single_swapped_device_created_at: 'Uitwisselingdatum:',
         devices_single_swapped_device_name_search: 'Zoek',
         devices_single_swapped_swapped_device_name: 'Uitwisselen',
         devices_single_swapped_device_table_no_record_found: 'Niet gevonden!',
         devices_single_swapped_device_table_loading: 'Laden',
         devices_single_swapped_device_table_row_per_page:
           'Resultaten per pagina',
         sidebar_swapped_devices_overview: 'Uitgewisselde devices',
         sidebar_swapped_devices: 'Uitgewisselde devices',
         swapped_devices_form_header: 'Uitgewisselde devices',
         devices_bulk_edit_registers_form_no_devices_error:
           'Selecteer devices om door te gaan.',
         devices_bulk_edit_firmware_modal_title:
           'Weet u zeker dat u een firmware update wilt uitvoeren?',
         devices_bulk_edit_firmware_modal_subtitle:
           'Wilt u firmwareversie ##firmware_value## in bulk bijwerken voor ##total_devices## devices?',
         devices_bulk_edit_registers_modal_title:
           'Weet u zeker dat u de groepsactie wilt uitvoeren?',
         devices_bulk_edit_registers_modal_subtitle:
           'Wilt u de registers voor ##total_devices## devices in een keer bijwerken?',
         devices_bulk_edit_registers_modal_cancel_button: 'Annuleer',
         devices_bulk_edit_registers_modal_submit_button: 'Bevestigen',
         devices_bulk_edit_firmware_modal_cancel_button: 'Annuleer',
         devices_bulk_edit_firmware_modal_submit_button: 'Bevestigen',
         devices_bulk_export_button: 'Export devices',
         projects_bulk_export_button: 'Export projecten',
         project_devices_bulk_export_button: 'Export projecten',
         devices_bulk_export_file_name: 'Device bulk export',
         projects_bulk_export_file_name: 'Project bulk export',
         project_devices_bulk_export_file_name: 'Device bulk export',
         sidebar_reporting_overview: 'Rapporten',
         sidebar_bulk_edit_ota_reporting: 'Updates',
         sidebar_bulk_reporting_registers_table_title: 'Groepswijzigingen',
         edit_ota_reporting: 'Update rapporten',
         reporting_ota_all: 'Alle',
         reporting_ota_success: 'Succesvol',
         reporting_ota_failure: 'Mislukt',
         reporting_ota_bulk_export_button: 'Exporteren',
         reporting_ota_filter_reset_button: 'Reset',
         reporting_ota_table_filter_search: 'Zoeken',
         reporting_ota_table_updated_at: 'Datum',
         reporting_ota_table_total_devices: 'Totaal aantal devices',
         reporting_ota_table_user: 'Gebruiker',
         reporting_ota_table_message: 'Opmerking',
         reporting_registers_table_user: 'Gebruiker',
         reporting_registers_table_message: 'Opmerking',
         reporting_ota_table_no_record_found: 'Geen zoekresultaten gevonden',
         reporting_ota_table_row_per_page: 'Resultaten per pagina',
         edit_ota_reporting_details: 'OTA Audit Details',
         reporting_ota_details_all_tab: 'Alle',
         reporting_ota_details_pending_tab: 'Bezig',
         reporting_ota_details_success_tab: 'Succesvol',
         reporting_ota_details_failure_tab: 'Mislukt',
         reporting_ota_details_bulk_export_button: 'Exporteren',
         reporting_ota_details_filter_reset_button: 'Reset',
         reporting_ota_details_table_filter_search: 'Zoeken',
         reporting_ota_details_message: 'Firmwareversie bijgewerkt naar',
         reporting_ota_details_table_created_at: 'Datum',
         reporting_ota_details_table_device: 'Device',
         reporting_ota_details_table_user: 'Gebruiker',
         reporting_ota_details_table_old_firmware_version:
           'Oude firmwareversie',
         reporting_ota_details_table_current_firmware_version:
           'Huidige firmware versie',
         reporting_ota_details_table_current_status: 'Status',
         reporting_ota_details_table_message: 'Bericht',
         reporting_ota_details_table_no_record_found:
           'Geen zoekresultaten gevonden',
         reporting_ota_details_table_row_per_page: 'Resultaten per pagina',
         reporting_ota_bulk_export_file_name: 'OTA Rapport',
         reporting_ota_details_bulk_export_file_name:
           'Bulk OTA Details Reporting',
         devices_single_swapped_bulk_export_button_file_name:
           'Swapped Devices Reporting',
         devices_single_swapped_bulk_export_button: 'Exporteren',
         reporting_registers_all: 'All',
         reporting_registers_success: 'Succesvol',
         reporting_registers_failure: 'Mislukt',
         reporting_registers_table_created_at: 'Datum',
         reporting_registers_table_total_devices: 'Totaal aantal apparaten',
         reporting_registers_bulk_export_button: 'Exporteren',
         reporting_registers_filter_reset_button: 'Reset',
         reporting_registers_table_filter_search: 'Zoeken',
         reporting_registers_table_no_record_found:
           'Geen zoekresultaten gevonden',
         reporting_registers_table_row_per_page: 'Resultaten per pagina',
         reporting_registers_table_title: 'Groepswijzigingen rapporten',
         reporting_registers_table_bulk_export_file_name:
           'Bulk Registers Reporting',
         registers_reporting_details_title: 'Details groepswijzigingen',
         reporting_registers_details_bulk_export_button: 'Exporteren',
         reporting_registers_details_filter_reset_button: 'Reset',
         reporting_registers_details_table_filter_search: 'Zoeken',
         reporting_registers_details_table_created_at: 'Datum',
         reporting_registers_details_table_device: 'Device',
         reporting_registers_details_table_user: 'Gebruiker',
         reporting_registers_details_table_message: 'Bericht',
         reporting_registers_details_table_failed_message:
           'Firmware version is niet ge-update naar',
         reporting_registers_details_table_pending_message:
           'Firmware versie updaten naar',
         reporting_registers_details_table_successful_message:
           'Firmware versie is ge-update naar',
         reporting_registers_details_table_no_record_found:
           'Geen zoekresultaten gevonden',
         reporting_registers_details_table_row_per_page:
           'Resultaten per pagina',
         reporting_registers_details_bulk_export_file_name:
           'Bulk Registers Details Reporting',
         devices_bulk_edit_ota_form_firmware_field_select_label:
           'Selecteer Firmware',
         dashboard_users_overview: 'Gebruiker overzicht',
         dashboard_users_title: 'Gebruikers',
         dashboard_devices_overview: 'Device overzicht',
         dashboard_devices_title: 'Devices',
         dashboard_commissioning_overview: 'Inbedrijfstelling',
         dashboard_commissioning_title: 'Inbedrijfstelling',
         devices_filters: 'Filters:',
         devices_submit_button: 'Gereed',
         device_single_device_documentation_tab: 'Docs',
         device_single_documentation_brochure_button: 'Brochure',
         device_single_documentation_installation_manual_button:
           'gebruikers/montagehandleiding',
         device_single_documentation_dimensional_drawing_button: 'Maattekening',
         device_single_documentation_parts_drawing_button: 'Onderdelentekening',
         device_single_documentation_flow_direction_button: 'Stromingsrichting',
         device_single_documentation_principle_diagram_button: 'Principeschema',
         device_single_documentation_information_end_user_button:
           'Informatie eindgebruiker',
         device_single_documentation_malfunction_key_button: 'Storingssleutel',
         reporting_ota_failed_message: 'Firmwareversie is niet bijgewerkt naar',
         reporting_ota_pending_message: 'Firmwareversie updaten naar',
         reporting_ota_successful_message: 'Firmwareversie bijgewerkt naar',
         ota_pending: 'Pending',
         ota_success: 'Successful',
         ota_failed: 'Failed',
         clear_devices_modal_title: 'Clear Device',
         clear_devices_modal_subtitle:
           'Weet u zeker dat u dit apparaat wilt wissen met serienummer <strong>##serial_number##</strong>',
         clear_devices_modal_cancel_button: 'Annuleer',
         clear_devices_modal_submit_button: 'Wis device',
         sidebar_fortes_roles: 'Rollen',
         sidebar_fortes_permissions: 'Permissie',
         roles_all: 'Alle',
         roles_active: 'Actief',
         roles_inactive: 'Inactief',
         roles_filter_delete_button: 'Verwijderen',
         roles_table_identifier: 'Identifier',
         roles_table_name_en: 'Name (EN)',
         roles_table_name_nl: 'Naam (NL)',
         roles_table_description_en: 'Omschrijving (EN)',
         roles_table_description_nl: 'Omschrijving (NL)',
         roles_table_status: 'Status',
         roles_table_updated_at: 'Bijgewerkt op',
         roles_filter_search: 'Zoeken',
         roles_no_record_found: 'Geen resultaten gevonden',
         roles_table_row_per_page: 'Resultaten per pagina',
         roles_bulk_no_record_is_selected_error_message:
           'Selecteer devices voor groeps deactivering.',
         roles_deactivated_message: 'Role is deactivated successfully',
         roles_activated_message: 'Rol is succesvol geactiveerd',
         roles_bulk_deactivated_message:
           '##ROLES_COUNT## Rollen zijn succesvol gedeactiveerd',
         roles_title: 'Rollen',
         roles_add_new: 'Rol toevoegen',
         roles_bread_crumb_main_title: 'Rollen',
         add_roles_bread_crumb_first_link: 'Toevoegen',
         edit_roles_bread_crumb_first_link: 'Rol wijzigen',
         add_roles_form_header: 'Rol toevoegen',
         edit_roles_form_header: 'Rol wijzigen',
         roles_form_role_identifier_field: 'Identifier',
         roles_identifier_required_form_error: 'Identifier is verplicht',
         roles_form_api_error: 'Internal Server Error',
         roles_form_unqiue_role_name_error: 'Identifier moet uniek zijn',
         roles_form_api_forbidden:
           'Gebruiker heeft geen rechten om een rol te maken',
         roles_add_form_success: 'Rol is succesvol aangemaakt',
         roles_edit_form_success: 'Rol is succesvol bijgewerkt',
         roles_form_add_role_button: 'Rol toevoegen',
         roles_form_edit_role_button: 'Rol wijzigen',
         roles_form_cancel_button: 'Annuleer',
         roles_form_permissions_title: 'Permissies',
         sidebar_fortes_modules: 'Modules',
         modules_all: 'Alle',
         modules_active: 'Actief',
         modules_inactive: 'Inactief',
         modules_filter_delete_button: 'Verwijderen',
         modules_table_identifier: 'Identifier',
         modules_table_name_en: 'Naam (EN)',
         modules_table_name_nl: 'Naam (NL)',
         modules_table_description_en: 'Omschrijving (EN)',
         modules_table_description_nl: 'Omschrijving (NL)',
         modules_table_status: 'Status',
         modules_table_updated_at: 'Bijgewerkt op',
         modules_filter_search: 'Zoeken',
         modules_no_record_found: 'Geen devices gevonden',
         modules_table_row_per_page: 'Resulataten per pagina',
         modules_bulk_no_record_is_selected_error_message:
           'Selecteer rijen voor bulkdeactivering.',
         modules_deactivated_message: 'Module is succesvol gedeactiveerd',
         modules_activated_message: 'Module is succesvol geactiveerd',
         modules_bulk_deactivated_message:
           '##MODULES_COUNT## modules zijn succesvol gedeactiveerd',
         modules_title: 'Modules',
         modules_add_new: 'Toevoegen',
         modules_bread_crumb_main_title: 'Modules',
         add_modules_bread_crumb_first_link: 'Module toevoegen',
         edit_modules_bread_crumb_first_link: 'Module wijzigen',
         add_modules_form_header: 'Module toevoegen',
         edit_modules_form_header: 'Module wijzigen',
         modules_form_module_identifier_field: 'Identifier',
         modules_identifier_required_form_error: 'Identifier is verplicht',
         modules_form_module_name_en_field: 'Naam (EN)',
         modules_form_module_name_nl_field: 'Naam (NL)',
         modules_name_en_required_form_error: 'Naam (EN) is verplicht',
         modules_name_nl_required_form_error: 'Naam (NL) is verplicht',
         modules_form_description_en_field: 'Omschrijving (EN)',
         modules_form_description_nl_field: 'Omschrijving (NL)',
         modules_form_api_error: 'Internal Server Error',
         modules_form_unqiue_module_name_error: 'Identifer moet uniek zijn',
         modules_form_api_forbidden:
           'Gebruiker heeft geen rechten om een module te maken',
         modules_add_form_success: 'Module is succesvol aangemaakt',
         modules_edit_form_success: 'Module is succesvol bijgewerkt',
         modules_form_add_module_button: 'Module toevoegen',
         modules_form_edit_module_button: 'Module wijzigen',
         modules_form_cancel_button: 'Annuleer',
         permissions_all: 'Alle',
         permissions_active: 'Actief',
         permissions_inactive: 'Inactief',
         permissions_filter_delete_button: 'Verwijderen',
         permissions_table_identifier: 'Identifier',
         permissions_table_module_name: 'Module',
         permissions_table_name_en: 'Naam (EN)',
         permissions_table_name_nl: 'Naam (NL)',
         permissions_table_description_en: 'Omschrijving (EN)',
         permissions_table_description_nl: 'Omschrijving (NL)',
         permissions_table_status: 'Status',
         permissions_filter_search: 'Zoeken',
         permissions_no_record_found: 'Geen device gevonden',
         permissions_table_row_per_page: 'Resulataten per pagina',
         permissions_bulk_no_record_is_selected_error_message:
           'Selecteer rijen voor bulkdeactivering.',
         permissions_deactivated_message:
           'Permissie is succesvol gedeactiveerd',
         permissions_activated_message: 'Permissie is succesvol geactiveerd',
         permissions_bulk_deactivated_message:
           '##PERMISSIONS_COUNT## permissies zijn succesvol gedeactiveerd',
         permissions_title: 'Permissies',
         permissions_add_new: 'Permissie toevoegen',
         permissions_bread_crumb_main_title: 'Permissie',
         add_permissions_bread_crumb_first_link: 'Permissie toevoegen',
         edit_permissions_bread_crumb_first_link: 'Permissie wijzigen',
         edit_permissions_form_header: 'Permissie wijzigen',
         add_permissions_form_header: 'Permissie toevoegen',
         permissions_form_permission_identifier_field: 'Identifier',
         permissions_form_permission_module_field: 'Module',
         permissions_form_permission_select_module_field: 'Selecteer module',
         permissions_module_required_form_error: 'Module is verplicht',
         permissions_identifier_required_form_error: 'Identifier is verplicht',
         permissions_form_permission_name_en_field: 'Name (EN)',
         permissions_form_permission_name_nl_field: 'Naam (NL)',
         permissions_form_permission_sub_module_field: 'Sub-Module',
         permissions_form_permission_select_sub_module_field:
           'Selecteer Sub-Module',
         permissions_sub_module_required_form_error: 'Sub-Module is Verplicht',
         permissions_name_required_form_error: 'Naam is Verplicht',
         permissions_form_description_en_field: 'Description (EN)',
         permissions_form_description_nl_field: 'Omschrijving (NL)',
         permissions_form_api_error: 'Internal Server Error',
         permissions_form_unqiue_permission_name_error:
           'Identifer moet uniek zijn',
         permissions_edit_form_success: 'Klant is succesvol bijgewerkt!',
         permissions_form_api_forbidden:
           'Gebruiker heeft geen rechten om permissie te maken',
         permissions_add_form_success: 'Permissie succesvol aangemaakt',
         permissions_form_add_permission_button: 'Permissie toevoegen',
         permissions_form_edit_permission_button: 'Permissie wijzigen',
         permissions_form_cancel_button: 'Annuleer',
         permissions_read: 'Lezen',
         permissions_create: 'Toevoegen',
         permissions_update: 'Bijwerken',
         permissions_delete: 'Verwijderen',
         permissions_status: 'Toggle Status',
         permissions_get_all: 'Get All',
         permissions_get_by_id: 'Get By Id',
         permissions_bulk_deactivate: 'Bulk De-activate',
         permissions_form_title: 'Permissies',
         duplicate_roles_form_header: 'Rol dupliceren',
         duplicate_roles_bread_crumb_first_link: 'Rol dupliceren',
         roles_duplicate_form_success: 'Rol is succesvol gedupliceerd',
         roles_filter_duplicate_button: 'Rol dupliceren',
         roles_pre_identifier_required_form_error:
           'Pre Identifier is verplicht',
         roles_form_pre_identifier_field: 'Pre Identifier',
         roles_form_pre_identifier_select: 'Select Pre Identifier',
         duplicate_roles_identifier_message:
           '<ul style="margin: "0", padding-left: 15px"> <li>For new client role always start the identifier with client_</li> <li>For new sub client role always start the identifier with service_client_</li> <li>For new fortes role always start the identifier with fortes_</li> </ul>',
         devices_single_device_counters_title: 'Device Counters',
         devices_single_device_counters_offline_counter: 'Offline Counter',
         devices_single_device_counters_online_counter: 'Online Counter',
         devices_single_device_counters_ota_fail_counter: 'OTA mislukt Counter',
         devices_single_device_counters_ota_update_counter:
           'OTA succesvol Counter',
         devices_single_device_counters_mbus_error_counter:
           'MBus error counter',
         devices_single_device_counters_modbus_error_counter:
           'Modbus error counter',
         devices_single_device_docs_directory: 'Directories',
         device_single_documentation_no_documents_found:
           'Geen documenten gevonden',
         reporting_registers_details_table_registers_pending:
           'Registers in afwachting',
         reporting_registers_details_table_registers_updated_fail:
           'Registers mislukt',
         reporting_registers_details_table_registers_updated_successful:
           'Registers succesvol',
         reporting_registers_details_table_status: 'Status',
         reporting_ota_message: 'Update Firmware versie naar',
         devices_bulk_edit_registers_form_fix_register_values_error:
           'Corrigeer de fouten in alle tabbladen',
         login_permission_error:
           'Verkrijg de juiste machtigingen van Fortes Administrator',
         monitoring_title: 'Monitoring',
         monitoring_vm_title: 'Virtual Machine',
         monitoring_ram_title: 'RAM',
         monitoring_cpu_title: 'CPU',
         monitoring_disk_title: 'Disk',
         monitoring_restarts_title: 'Restarts',
         monitoring_uptime_title: 'Uptime',
         monitoring_services_title: 'Services',
         monitoring_service_table_service_name: 'Name',
         monitoring_service_table_service_status: 'Status',
         be_user_service: 'Users',
         be_project_service: 'Projects',
         be_export_service: 'Export',
         be_preset_service: 'Preset',
         be_status_updater_service: 'Status Updater',
         be_email_service: 'Email',
         be_permissions_rbac_service: 'Permissions',
         be_upload_images_service: 'Upload Images',
         be_db_archive_service: 'DB Archive',
         be_diagnostic_service: 'Diagnostic',
         be_external_service: 'External APIs',
         be_mqtt_updater_service: 'MQTT',
         fortes_document_service: 'Fortes Document',
         external_client_service: 'External Client',
         devices_form_service_client_field: 'Service klanten',
         sidebar_3scale_users: '3Scale Client Users',
         three_scale_users_title: '3Scale Client Users',
         three_scale_users_table_name: 'Name',
         three_scale_users_table_email: 'Email',
         three_scale_users_table_client: 'Client',
         three_scale_users_table_updated_at: 'Updated At',
         three_scale_users_bread_crumb_main_title: '3Scale Client Users',
         add_three_scale_users_bread_crumb_first_link: 'Add 3Scale Client User',
         add_three_scale_users_form_header: 'Add 3Scale Client User',
         three_scale_users_form_name_field: 'Name',
         three_scale_users_form_email_field: 'Email Address',
         three_scale_users_form_client_field: 'Client',
         three_scale_users_form_password_field: 'Password',
         three_scale_users_name_required_form_error: 'Name is required',
         three_scale_users_email_valid_form_error: 'Must be a valid email',
         three_scale_users_email_required_form_error: 'Email is required',
         three_scale_users_client_required_form_error: 'Client is required',
         three_scale_users_password_required_form_error:
           'Password must contain at least 8 characters with at least one uppercase letter, at least one number and at least one special character.',
         three_scale_users_no_record_found: 'No Records Found',
         three_scale_users_table_row_per_page: 'Rows Per Page',
         three_scale_users_add_new: 'Add 3Scale Client User',
         three_scale_users_filter_search: 'Search',
         three_scale_user_single_first_bread_crumb: '3Scale Client Users',
         three_scale_user_single_second_bread_crumb: 'View 3Scale Client Users',
         three_scale_user_single_apis_tab: 'API Keys',
         three_scale_user_single_edit_button_label: 'Edit',
         three_scale_user_single_user_label: 'User',
         three_scale_user_single_email_label: 'Email',
         three_scale_user_single_client_label: 'Client',
         edit_three_scale_users_form_header: 'Edit 3Scale Client User',
         edit_three_scale_users_bread_crumb_first_link:
           'Edit 3Scale Client User',
         device_single_temperature_start_date: 'Start Date',
         device_single_temperature_end_date: 'End Date',
         device_single_temperature_start_date_error:
           'Start Date must be less than or equal to End Date',
         sidebar_3scale_admins: '3Scale Admin Users',
         three_scale_user_admins_title: '3Scale Admin Users',
         three_scale_user_admins_table_name: 'Name',
         three_scale_user_admins_table_email: 'Email',
         three_scale_user_admins_table_updated_at: 'Updated At',
         three_scale_user_admins_bread_crumb_main_title: '3Scale Admin Users',
         add_three_scale_user_admins_bread_crumb_first_link:
           'Add 3Scale Admin User',
         add_three_scale_user_admins_form_header: 'Add 3Scale Admin User',
         three_scale_user_admins_form_name_field: 'Name',
         three_scale_user_admins_form_email_field: 'Email Address',
         three_scale_user_admins_form_client_field: 'Admin',
         three_scale_user_admins_form_password_field: 'Password',
         three_scale_user_admins_name_required_form_error: 'Name is required',
         three_scale_user_admins_email_valid_form_error:
           'Must be a valid email',
         three_scale_user_admins_email_required_form_error: 'Email is required',
         three_scale_user_admins_password_required_form_error:
           'Password must contain at least 8 characters with at least one uppercase letter, at least one number and at least one special character.',
         three_scale_user_admins_no_record_found: 'No Records Found',
         three_scale_user_admins_table_row_per_page: 'Rows Per Page',
         three_scale_user_admins_add_new: 'Add 3Scale Admin User',
         three_scale_user_admins_filter_search: 'Search',
         three_scale_user_admin_single_first_bread_crumb: '3Scale Admin Users',
         three_scale_user_admin_single_second_bread_crumb:
           'View 3Scale Admin Users',
         three_scale_user_admin_single_apis_tab: 'API Keys',
         three_scale_user_admin_single_edit_button_label: 'Edit',
         three_scale_user_admin_single_user_label: 'User',
         three_scale_user_admin_single_email_label: 'Email',
         edit_three_scale_user_admins_form_header: 'Edit 3Scale Admin User',
         edit_three_scale_user_admins_bread_crumb_first_link:
           'Edit 3Scale Admin User',
         projects_project_order_number_required_form_error:
           'Project Order Number is required',
         projects_form_project_order_number_field: 'Project Order Number',
         client_error_single_device_address: 'Installatie adres',
         navbar_notification_mark_all_as_read: 'Mark all as read',
         navbar_notification_view_all: 'View All',
         notifications_title: 'Meldingen',
         notifications_all: 'All',
         notifications_unread: 'Unread',
         notifications_read: 'Read',
         notifications_table_title: 'Title (EN)',
         notifications_table_description: 'Description (EN)',
         notifications_table_notification_type: 'Notification Type',
         notifications_table_notification_category: 'Notification Category',
         notifications_table_date: 'Date',
         notifications_filter_search: 'Search',
         notifications_no_record_found: 'No Records Found',
         notifications_table_row_per_page: 'Rows per page',
         add_notifications_form_header: 'Add Notification',
         notifications_title_en_required_form_error: 'Title (EN) is required',
         notifications_title_nl_required_form_error: 'Title (NL) is required',
         notifications_description_en_required_form_error:
           'Description (EN) is required',
         notifications_description_nl_required_form_error:
           'Description (NL) is required',
         notifications_form_notification_type_field: 'Notification Type',
         notifications_notification_type_required_form_error:
           'Notification Type is required',
         notifications_form_notification_category_field:
           'Notification Category',
         notifications_notification_category_required_form_error:
           'Notification Category is required',
         notifications_form_title_en_field: 'Title (EN)',
         notifications_form_title_nl_field: 'Title (NL)',
         notifications_form_start_date_field: 'Start Date',
         notifications_form_end_date_field: 'End Date',
         notifications_form_description_en_field: 'Description (EN)',
         notifications_form_description_nl_field: 'Description (NL)',
         notifications_form_add_button: 'Add Notification',
         notifications_form_edit_button: 'Edit Notification',
         notifications_form_cancel_button: 'Cancel',
         notifications_add_form_success: 'Notification is added successfully',
         notifications_form_api_error: 'Internal Server Error',
         notification_type_information: 'Information',
         notification_type_warning: 'Warning',
         notification_category_simple: 'Simple',
         notification_category_timebased: 'Timebased',
         edit_notifications_form_header: 'Edit Notification',
         sidebar_notification_menu: 'Notifications',
         pop_over_close_button: 'Close',
         docs_user_type_end_user: 'Eindgebruiker',
         docs_user_type_installer: 'Installateur',
         devices_single_device_docs_no_directory_found: 'No Directories Found',
         device_single_status_tab: 'Visuals',
         device_single_status_picture_tab: 'Picture',
         device_single_status_principle_tab: 'Principle',
         device_single_status_table_loading: 'Loading ...',
         device_single_status_mbus_export_button: 'Export',
         device_single_status_mbus_date_range_filter_label: 'Date Range',
         device_single_status_mbus_date_export_label: 'Date',
         device_single_status_mbus_time_export_label: 'Time',
         device_single_status_mbus_port_export_label: 'Port',
         device_single_status_mbus_meter_connected_at_export_label:
           'Meter Connected At',
         device_single_status_mbus_meter_status_export_label: 'Meter Status',
         device_single_status_mbus_meter_type_export_label: 'Meter Type',
         device_single_status_mbus_select_meter_type_export_label:
           'Select Meter Type',
         device_single_status_mbus_select_meter_type_error:
           'Meter Type is missing',
         device_single_status_mbus_export_internal_server_error:
           'Internal Server Error',
         device_single_status_mbus_export_no_data_found_error:
           'No record found',
         sidebar_diagnostic_formulas: 'Diagnostic Formulas',
         diagnostic_formulas_all: 'All',
         diagnostic_formulas_active: 'Active',
         diagnostic_formulas_inactive: 'InActive',
         diagnostic_formulas_filter_delete_button: 'Delete',
         diagnostic_formulas_table_identifier: 'Identifier',
         diagnostic_formulas_table_name_en: 'Name (EN)',
         diagnostic_formulas_table_name_nl: 'Name (NL)',
         diagnostic_formulas_table_description_en: 'Description (EN)',
         diagnostic_formulas_table_description_nl: 'Description (NL)',
         diagnostic_formulas_table_formula: 'Formula',
         diagnostic_formulas_table_status: 'Status',
         diagnostic_formulas_table_updated_at: 'Updated At',
         diagnostic_formulas_filter_search: 'Search',
         diagnostic_formulas_no_record_found: 'No Record Found',
         diagnostic_formulas_table_row_per_page: 'Rows Per Page',
         diagnostic_formulas_bulk_no_record_is_selected_error_message:
           'Please select records for bulk de-activation.',
         diagnostic_formulas_deactivated_message:
           'Diagnostic is deactivated successfully',
         diagnostic_formulas_activated_message:
           'Diagnostic is activated successfully',
         diagnostic_formulas_bulk_deactivated_message:
           '##DIAGNOSTICS_COUNT## Diagnostics are deactivated successfully',
         diagnostic_formulas_title: 'Diagnostics Formulas',
         diagnostic_formulas_add_new: 'Add New',
         diagnostic_formulas_bread_crumb_main_title: 'Diagnostic Formulas',
         add_diagnostic_formulas_bread_crumb_first_link:
           'Add Diagnostic Formulas',
         edit_diagnostic_formulas_bread_crumb_first_link:
           'Edit Diagnostic Formulas',
         add_diagnostic_formulas_form_header: 'Add Diagnostic Formulas',
         edit_diagnostic_formulas_form_header: 'Edit Diagnostic Formulas',
         diagnostic_formulas_form_diagnostic_formulas_identifier_field:
           'Identifier',
         diagnostic_formulas_identifier_required_form_error:
           'Identifier is required',
         diagnostic_formulas_form_diagnostic_formulas_name_en_field:
           'Name (EN)',
         diagnostic_formulas_form_diagnostic_formulas_name_nl_field:
           'Name (NL)',
         diagnostic_formulas_name_en_required_form_error:
           'Name (EN) is required',
         diagnostic_formulas_name_nl_required_form_error:
           'Name (NL) is required',
         diagnostic_formulas_formula_required_form_error: 'Formula is required',
         diagnostic_formulas_form_description_en_field: 'Description (EN)',
         diagnostic_formulas_form_description_nl_field: 'Description (NL)',
         diagnostic_formulas_form_forumla_field: 'Formula',
         diagnostic_formulas_form_api_error: 'Internal Server Error',
         diagnostic_formulas_form_unqiue_diagnostic_formulas_name_error:
           'Identifer must be unique',
         diagnostic_formulas_form_api_forbidden:
           'User has no permissions to create diagnostic_formulas',
         diagnostic_formulas_add_form_success:
           'Diagnostic is created successfully',
         diagnostic_formulas_edit_form_success:
           'Diagnostic is updated successfully',
         diagnostic_formulas_form_add_diagnostic_formulas_button:
           'Add Diagnostic',
         diagnostic_formulas_form_edit_diagnostic_formulas_button:
           'Edit Diagnostic',
         diagnostic_formulas_form_cancel_button: 'Cancel',
         sidebar_diagnostic_categories: 'Diagnostic Categories',
         diagnostic_categories_all: 'All',
         diagnostic_categories_active: 'Active',
         diagnostic_categories_inactive: 'InActive',
         diagnostic_categories_filter_delete_button: 'Delete',
         diagnostic_categories_table_identifier: 'Identifier',
         diagnostic_categories_table_name_en: 'Name (EN)',
         diagnostic_categories_table_name_nl: 'Name (NL)',
         diagnostic_categories_table_description_en: 'Description (EN)',
         diagnostic_categories_table_description_nl: 'Description (NL)',
         diagnostic_categories_table_status: 'Status',
         diagnostic_categories_table_updated_at: 'Updated At',
         diagnostic_categories_filter_search: 'Search',
         diagnostic_categories_no_record_found: 'No Record Found',
         diagnostic_categories_table_row_per_page: 'Rows Per Page',
         diagnostic_categories_bulk_no_record_is_selected_error_message:
           'Please select records for bulk de-activation.',
         diagnostic_categories_deactivated_message:
           'Category is deactivated successfully',
         diagnostic_categories_activated_message:
           'Category is activated successfully',
         diagnostic_categories_bulk_deactivated_message:
           '##DIAGNOSTICS_COUNT## Categories are deactivated successfully',
         diagnostic_categories_title: 'Diagnostic Categories',
         diagnostic_categories_add_new: 'Add New',
         diagnostic_categories_bread_crumb_main_title: 'Diagnostic Categories',
         add_diagnostic_categories_bread_crumb_first_link:
           'Add Diagnostic Category',
         edit_diagnostic_categories_bread_crumb_first_link:
           'Edit Diagnostic Category',
         add_diagnostic_categories_form_header: 'Add Diagnostic Category',
         edit_diagnostic_categories_form_header: 'Edit Diagnostic Category',
         diagnostic_categories_form_diagnostic_categories_identifier_field:
           'Identifier',
         diagnostic_categories_identifier_required_form_error:
           'Identifier is required',
         diagnostic_categories_form_diagnostic_categories_name_en_field:
           'Name (EN)',
         diagnostic_categories_form_diagnostic_categories_name_nl_field:
           'Name (NL)',
         diagnostic_categories_name_en_required_form_error:
           'Name (EN) is required',
         diagnostic_categories_name_nl_required_form_error:
           'Name (NL) is required',
         diagnostic_categories_form_description_en_field: 'Description (EN)',
         diagnostic_categories_form_description_nl_field: 'Description (NL)',
         diagnostic_categories_form_api_error: 'Internal Server Error',
         diagnostic_categories_form_unqiue_diagnostic_categories_name_error:
           'Identifer must be unique',
         diagnostic_categories_form_api_forbidden:
           'User has no permissions to create diagnostic_categories',
         diagnostic_categories_add_form_success:
           'Category is created successfully',
         diagnostic_categories_edit_form_success:
           'Category is updated successfully',
         diagnostic_categories_form_add_diagnostic_categories_button:
           'Add Category',
         diagnostic_categories_form_edit_diagnostic_categories_button:
           'Edit Category',
         diagnostic_categories_form_cancel_button: 'Cancel',
         sidebar_diagnostics_overview: 'Diagnostics',
         sidebar_firmware_menu: 'Firmware',
         firmwares_all: 'All',
         firmwares_active: 'Active',
         firmwares_inactive: 'InActive',
         firmwares_table_identifier: 'Identifier',
         firmwares_table_name_en: 'Name (EN)',
         firmwares_table_name_nl: 'Name (NL)',
         firmwares_table_firmware: 'Firmware',
         firmwares_table_status: 'Status',
         firmwares_table_updated_at: 'Updated At',
         firmwares_filter_search: 'Search',
         firmwares_no_record_found: 'No Record Found',
         firmwares_table_row_per_page: 'Rows Per Page',
         firmwares_bulk_no_record_is_selected_error_message:
           'Please select records for bulk de-activation.',
         firmwares_deactivated_message: 'Firmware is deactivated successfully',
         firmwares_activated_message: 'Firmware is activated successfully',
         firmwares_title: 'Firmware',
         firmwares_add_new: 'Add New',
         firmwares_bread_crumb_main_title: 'Firmware',
         add_firmwares_bread_crumb_first_link: 'Add Firmware',
         edit_firmwares_bread_crumb_first_link: 'Edit Firmware',
         add_firmwares_form_header: 'Add Firmware',
         edit_firmwares_form_header: 'Edit Firmware',
         firmwares_form_firmware_identifier_field: 'Identifier',
         firmwares_identifier_required_form_error: 'Identifier is required',
         firmwares_form_firmware_name_en_field: 'Name (EN)',
         firmwares_form_firmware_name_nl_field: 'Name (NL)',
         firmwares_form_firmware_firmware_field: 'Firmware',
         firmwares_name_en_required_form_error: 'Name (EN) is required',
         firmwares_name_nl_required_form_error: 'Name (NL) is required',
         firmwares_form_description_en_field: 'Description (EN)',
         firmwares_form_description_nl_field: 'Description (NL)',
         firmwares_form_api_error: 'Internal Server Error',
         firmwares_form_unqiue_firmware_name_error: 'Identifer must be unique',
         firmwares_form_api_forbidden:
           'User has no permissions to create firmware',
         firmwares_add_form_success: 'Firmware is created successfully',
         firmwares_edit_form_success: 'Firmware is updated successfully',
         firmwares_form_edit_firmware_button: 'Edit Firmware',
         firmwares_form_cancel_button: 'Cancel',
         diagnostic_flows_all: 'All',
         diagnostic_flows_active: 'Active',
         diagnostic_flows_inactive: 'InActive',
         diagnostic_flows_filter_delete_button: 'Delete',
         diagnostic_flows_table_identifier: 'Identifier',
         diagnostic_flows_table_category: 'Category',
         diagnostic_flows_table_name_en: 'Name (EN)',
         diagnostic_flows_table_name_nl: 'Name (NL)',
         diagnostic_flows_table_description_en: 'Description (EN)',
         diagnostic_flows_table_description_nl: 'Description (NL)',
         diagnostic_flows_table_status: 'Status',
         diagnostic_flows_table_updated_at: 'Updated At',
         diagnostic_flows_filter_search: 'Search',
         diagnostic_flows_no_record_found: 'No Record Found',
         diagnostic_flows_table_row_per_page: 'Rows Per Page',
         diagnostic_flows_bulk_no_record_is_selected_error_message:
           'Please select records for bulk de-activation.',
         diagnostic_flows_deactivated_message:
           'Flow is deactivated successfully',
         diagnostic_flows_activated_message: 'Flow is activated successfully',
         diagnostic_flows_bulk_deactivated_message:
           '##DIAGNOSTICS_COUNT## Flows are deactivated successfully',
         diagnostic_flows_title: 'Diagnostic Flows',
         diagnostic_flows_add_new: 'Add New',
         diagnostic_flows_bread_crumb_main_title: 'Diagnostic Flows',
         add_diagnostic_flows_bread_crumb_first_link: 'Add Diagnostic Flow',
         edit_diagnostic_flows_bread_crumb_first_link: 'Edit Diagnostic Flow',
         add_diagnostic_flows_form_header: 'Add Diagnostic Flow',
         edit_diagnostic_flows_form_header: 'Edit Diagnostic Flow',
         diagnostic_flows_form_diagnostic_flows_identifier_field: 'Identifier',
         diagnostic_flows_identifier_required_form_error:
           'Identifier is required',
         diagnostic_flows_form_diagnostic_flows_name_en_field: 'Name (EN)',
         diagnostic_flows_form_diagnostic_flows_name_nl_field: 'Name (NL)',
         diagnostic_flows_name_en_required_form_error: 'Name (EN) is required',
         diagnostic_flows_name_nl_required_form_error: 'Name (NL) is required',
         diagnostic_flows_category_required_form_error: 'Category is required',
         diagnostic_flows_form_description_en_field: 'Description (EN)',
         diagnostic_flows_form_description_nl_field: 'Description (NL)',
         diagnostic_flows_form_flow_condition_error_field:
           'Condition must have true and false rules',
         diagnostic_flows_form_flow_modal_title_field: 'Are you sure?',
         diagnostic_flows_form_flow_modal_subtitle_field:
           'You want to save the flow changes in browser?',
         diagnostic_flows_form_flow_modal_cancel_button_field: 'Cancel',
         diagnostic_flows_form_flow_modal_save_button_field: 'Save in Browser',
         diagnostic_flows_form_flow_modal_save_success_message:
           'Flow is successfully saved in Browser',
         diagnostic_flows_form_featured_image_field: 'Featured Image',
         diagnostic_flows_form_upload_featured_image_label:
           'Upload Featured Image',
         diagnostic_flows_form_thumbnail_field: 'Thumbnail',
         diagnostic_flows_form_upload_thumbnail_image_label:
           'Upload Thumbnail Image',
         diagnostic_flows_form_api_error: 'Internal Server Error',
         diagnostic_flows_form_unqiue_diagnostic_flows_name_error:
           'Identifer must be unique',
         diagnostic_flows_form_api_forbidden:
           'User has no permissions to create diagnostic_flows',
         diagnostic_flows_add_form_success:
           'Diagnostic Flow is created successfully',
         diagnostic_flows_edit_form_success:
           'Diagnostic Flow is updated successfully',
         diagnostic_flows_form_add_diagnostic_flows_button: 'Add Flow',
         diagnostic_flows_form_edit_diagnostic_flows_button: 'Edit Flow',
         diagnostic_flows_form_cancel_button: 'Cancel',
         audits_select_event_type_error_option: 'Error',
         diagnostic_flows_form_flow_field: 'Flow',
         diagnostic_flows_form_flow_condition_button: 'Condition',
         diagnostic_flows_form_flow_timer_button: 'Timer',
         diagnostic_flows_form_flow_normal_node_button: 'Node',
         diagnostic_flows_form_flow_download_image_button: 'Download Image',
         diagnostic_flows_form_flow_start_button: 'Start',
         diagnostic_flows_form_flow_end_button: 'End',
         devices_bulk_export_mbus_historic_data_button: 'Download Mbus Data',
         devices_bulk_export_mbus_historic_data_file_name: 'Mbus Historic Data',
         devices_bulk_mbus_download_title: 'Download Mbus Historical Data',
         devices_bulk_bulk_mbus_download_form_date_range: 'Date Range:',
         devices_bulk_bulk_mbus_download_form_submit_button:
           'Download Mbus Data',
         devices_bulk_bulk_mbus_download_form_cancel_button: 'Cancel',
         devices_bulk_mbus_download_form_success:
           'Mbus Historical Data is emailed to ##EMAIL## successfully!',
         devices_bulk_mbus_download_form_api_error: 'Internal Server Error',
         devices_bulk_mbus_download_no_devices_error: '',
         devices_bulk_mbus_download_form_no_devices_error:
           'Please select devices to proceed.',
         devices_bulk_bulk_mbus_download_modal_title: 'Are you sure?',
         devices_bulk_mbus_download_modal_subtitle:
           'You want to you want to run a bulk download historical Mbus data?',
         devices_bulk_mbus_download_modal_cancel_button: 'Cancel',
         devices_bulk_mbus_download_modal_submit_button: 'Confirm',
         projects_bulk_export_mbus_historic_data_button: 'Download Mbus Data',
         projects_bulk_export_mbus_historic_data_file_name:
           'Mbus Historic Data',
         projects_bulk_mbus_download_title: 'Download Mbus Historical Data',
         projects_bulk_bulk_mbus_download_form_date_range: 'Date Range:',
         projects_bulk_bulk_mbus_download_form_data_export_type:
           'Date Export Type',
         projects_bulk_bulk_mbus_download_form_data_export_type_combined:
           'Combined',
         projects_bulk_bulk_mbus_download_form_data_export_type_daily: 'Daily',
         projects_bulk_bulk_mbus_download_form_data_export_type_normal:
           'Normal',
         projects_bulk_bulk_mbus_download_form_submit_button:
           'Download Mbus Data',
         projects_bulk_bulk_mbus_download_form_cancel_button: 'Cancel',
         projects_bulk_mbus_download_form_success:
           'Mbus Historical Data is emailed to ##EMAIL## successfully!',
         projects_bulk_mbus_download_form_api_error: 'Internal Server Error',
         projects_bulk_mbus_download_no_projects_error: '',
         projects_bulk_mbus_download_form_no_projects_error:
           'Please select projects to proceed.',
         projects_bulk_bulk_mbus_download_modal_title: 'Are you sure?',
         projects_bulk_mbus_download_modal_subtitle:
           'You want to you want to run a bulk download historical Mbus data?',
         projects_bulk_mbus_download_modal_cancel_button: 'Cancel',
         projects_bulk_mbus_download_modal_submit_button: 'Confirm',
         projects_bulk_mbus_selected_projects: 'Projects Selected',
         sidebar_diagnostic_flows: 'Diagnostic Flows',
         diagnostic_flows_form_diagnostic_flows_category_field:
           'Diagnostic Flow Category',
         diagnostics_send_message_modal_registers_label: 'Registers',
         diagnostics_send_message_modal_operator_label: 'Operator',
         diagnostics_send_message_modal_value_label: 'Value',
         diagnostics_send_message_modal_registers_label_default_option:
           'Select Register',
         diagnostics_send_message_modal_save_button: 'Save',
         diagnostics_send_message_modal_cancel_button: 'Cancel',
         diagnostics_condition_modal_sensor_type_label: 'Sensor Type',
         diagnostics_condition_modal_modbus_label: 'Modbus Sensor',
         diagnostics_condition_modal_mbus_label: 'Mbus Sensor',
         diagnostics_condition_modal_formula_output_label: 'Formula Output',
         diagnostics_condition_modal_meter_type_label: 'Meter Type',
         diagnostics_condition_modal_meter_type_label_default_option:
           'Select Meter Type',
         diagnostics_condition_modal_operator_label: 'Operator',
         diagnostics_condition_modal_operator_option_1: 'Less',
         diagnostics_condition_modal_operator_option_2: 'Less than equal to',
         diagnostics_condition_modal_operator_option_3: 'Equal to',
         diagnostics_condition_modal_operator_option_4: 'Greater',
         diagnostics_condition_modal_operator_option_5: 'Greater than equal to',
         diagnostics_condition_modal_operator_option_6: 'Not Equal to',
         diagnostics_condition_modal_register_label: 'Register',
         diagnostics_condition_modal_value_label: 'Value',
         diagnostics_condition_modal_timer_label: 'Timer',
         diagnostics_condition_modal_retries_label: 'Retries',
         diagnostics_condition_modal_delay_label: 'Delay',
         diagnostics_condition_modal_times_label: 'times',
         diagnostics_condition_modal_second_label: 'sec',
         diagnostics_condition_modal_save_button: 'Save',
         diagnostics_condition_modal_cancel_button: 'Cancel',
         extra_white_space_form_error: 'Extra White space is not allowed',
         diagnostic_flows_form_incomplete_flow_error:
           'Please double check edges and the nodes with black color and red animation',
         diagnostic_flows_menu_information: 'Information',
         diagnostic_flows_menu_modbus: 'Modbus',
         diagnostic_flows_menu_message: 'Message',
         diagnostic_flows_menu_condition: 'Condition',
         diagnostic_flows_menu_user_interaction: 'User Interaction',
         diagnostic_flows_menu_steps: 'Page',
         diagnostic_flows_menu_timer: 'Timer',
         diagnostic_flows_menu_formula: 'Formula',
         diagnostic_flows_form_user_interaction_operation_type:
           'Operation Type',
         diagnostic_flows_form_user_interaction_operation_type_default_option:
           'Select Operation Type',
         diagnostic_flows_form_user_interaction_operation_type_option_choose_mbus_meter:
           'Choose Mbus Meter',
         diagnostic_flows_form_user_interaction_operation_type_option_choose_faucet:
           'Choose Faucet Register',
         diagnostic_flows_form_user_interaction_field_type_option_select_field:
           'Select Field Type',
         diagnostic_flows_form_user_interaction_field_type: 'Field Type',
         diagnostic_flows_form_user_interaction_field_type_default_option:
           'Select Field Type',
         diagnostic_flows_form_user_interaction_field_type_option_checkbox:
           'Checkbox',
         diagnostic_flows_form_user_interaction_field_type_option_radio_button:
           'Radio Button',
         diagnostic_flows_form_user_interaction_field_type_option_text_field:
           'Text Field',
         diagnostic_flows_form_user_interaction_description_en:
           'Description (EN)',
         diagnostic_flows_form_user_interaction_description_nl:
           'Description (NL)',
         diagnostic_flows_form_steps_label_en: 'Description (EN)',
         diagnostic_flows_form_steps_label_nl: 'Description (NL)',
         diagnostic_formulas_form_diagnostic_formulas_retries_field: 'Retries',
         diagnostic_formulas_form_diagnostic_formulas_delay_field: 'Delay',
         diagnostic_formulas_form_diagnostic_formulas_unit_field:
           'Formula Unit',
         diagnostic_formulas_form_forumla_field_placeholder:
           'Please choose @ to select Modbus or Mbus Registers',
         device_single_diagnostic_flow_missing_pages_error:
           '##SELECTED_FLOW## has no pages. Please add pages in the flow to proceed.',
         device_single_stepper_choose_flow_title: 'Choose the flow',
         device_single_stepper_choose_explanation_of_flow_wizard_title:
           'Explanation of the wizard',
         device_single_stepper_result_title: 'Result',
         diagnostic_flows_form_delete_node_modal_title: 'Are you sure?',
         diagnostic_flows_form_delete_node_modal_subtitle:
           'You want to you want to delete ##NODE_LABEL## node?',
         diagnostic_flows_form_delete_node_modal_cancel_button: 'Cancel',
         diagnostic_flows_form_delete_node_modal_delete_button: 'Delete',
         diagnostic_flows_form_loading_text: 'Loading ...',
         diagnostic_flows_form_processing_text: 'Processing',
         diagnostic_flows_form_main_error_text: 'Flow is stopped due to error',
         diagnostic_flows_form_main_success_text:
           'Flow has completed successfully',
         devices_bulk_bulk_mbus_download_form_data_type: 'Data Type',
         devices_bulk_bulk_mbus_download_form_export_type: 'Export Type',
         devices_bulk_bulk_mbus_download_form_data_type_daily: 'Daily',
         devices_bulk_bulk_mbus_download_form_data_type_hourly: 'Hourly',
         devices_bulk_bulk_mbus_download_form_export_type_combined: 'Combined',
         devices_bulk_bulk_mbus_download_form_export_type_separated:
           'Separated',
         diagnostic_flows_form_main_stopped_text: 'Flow is stopped by the user',
         diagnostic_execution_title: 'Diagnostic Executions',
         diagnostic_execution_table_created_at: 'Created At',
         diagnostic_execution_table_device: 'Device',
         diagnostic_execution_table_user: 'User',
         diagnostic_execution_table_flow: 'Flow',
         diagnostic_execution_table_status: 'Status',
         diagnostic_execution_table_filter_search: 'Search',
         diagnostic_executions_tab_all: 'All',
         diagnostic_executions_tab_completed: 'Completed',
         diagnostic_executions_tab_in_progress: 'In Progress',
         diagnostic_executions_tab_stopped: 'Stopped',
         diagnostic_executions_tab_error: 'Error',
         diagnostic_executions_table_no_record_found: 'No Records Found',
         diagnostic_executions_table_row_per_page: 'Rows Per Page',
         diagnostic_flows_menu_result: 'Result',
         diagnostic_flows_menu_result_form_description_en: 'Description (EN)',
         diagnostic_flows_menu_result_form_description_nl: 'Description (NL)',
         diagnostic_flows_menu_result_form_cancel_button: 'Cancel',
         diagnostic_flows_menu_result_form_save_button: 'Save',
         diagnostic_flows_menu_page_form_cancel_button: 'Cancel',
         diagnostic_flows_menu_page_form_save_button: 'Save',
         diagnostic_flows_menu_information_form_description_en:
           'Description (EN)',
         diagnostic_flows_menu_information_form_description_nl:
           'Description (NL)',
         diagnostic_flows_menu_information_form_cancel_button: 'Cancel',
         diagnostic_flows_menu_information_form_save_button: 'Save',
         sidebar_diagnostic_execution_title: 'Diagnostic Executions',
         diagnostic_flow_execution_single_first_bread_crumb:
           'Diagnostic Executions',
         diagnostic_flow_execution_single_second_bread_crumb:
           'View Diagnostic Execution',
         diagnostic_flow_execution_single_output_tab: 'Output',
         diagnostic_flow_execution_single_device_name: 'Device',
         diagnostic_flow_execution_single_flow: 'Flow',
         diagnostic_flow_execution_single_user: 'User',
         diagnostic_flow_execution_single_status: 'Status',
         diagnostic_flow_execution_single_created_at: 'Created At',
         diagnostic_flow_execution_single_bulk_export_file_name:
           'Bulk Diagnostic Executions',
         diagnostic_flow_execution_single_bulk_export_date: 'Date',
         diagnostic_flow_execution_single_bulk_export_time: 'Time',
         diagnostic_flow_execution_single_bulk_export_device_name:
           'Device Name',
         diagnostic_flow_execution_single_bulk_export_device_serial_number_mercurius:
           'Serial number Mercurius',
         diagnostic_flow_execution_single_bulk_export_device_user_full_name:
           'User',
         diagnostic_flow_execution_single_bulk_export_device_flow_name_en:
           'Flow Name (EN)',
         diagnostic_flow_execution_single_bulk_export_device_flow_name_nl:
           'Flow Name (NL)',
         diagnostic_flow_execution_single_bulk_export_device_status: 'Status',
         diagnostic_flows_form_main_flow_already_running_error_text:
           'A flow is already running on this device. Multiple flow executions are not allowed',
         diagnostic_flows_form_open_faucet_on_option: 'On',
         diagnostic_flows_form_open_faucet_off_option:
           'Off (Flow will go in error mode)',
         diagnostic_flows_form_mbus_meters_error:
           'Device does not have flow required Mbus Meters',
         diagnostic_flows_result_title: 'Result',
         duplicate_diagnostic_flows_form_header: 'Duplicate Diagnostic Flow',
         duplicate_diagnostic_flows_bread_crumb_first_link:
           'Duplicate Diagnostic Flow',
         diagnostic_flows_form_duplicate_diagnostic_flows_button: 'Duplicate',
         diagnostic_flows_duplicate_form_success:
           'Diagnostic Flow is duplicated successfully',
         diagnostic_flows_filter_duplicate_button: 'Duplicate Flow',
         diagnostic_flows_menu_result_form_id: 'Id',
         audits_street_number_filter_label: 'Straat',
         audits_house_number_filter_label: 'Huisnummer',
         diagnostic_execution_counter_title: 'Diagnostic Execution Counters',
         diagnostic_execution_counter_table_id: 'Id',
         diagnostic_execution_counter_table_counter: 'Counters',
         diagnostic_execution_counter_table_flow: 'Flow',
         diagnostic_execution_counter_table_result: 'Result',
         diagnostic_execution_counter_table_filter_search: 'Search',
         diagnostic_execution_counter_table_no_record_found: 'No Records Found',
         diagnostic_execution_counter_table_row_per_page: 'Rows Per Page',
         sidebar_diagnostic_flow_execution_counter: 'Diagnostic Flow Counters',
         device_single_firmware_hiu_label: 'Firmware HIU',
         device_single_firmware_mercurius_label: 'Firmware Mercurius',
         custom_popover_105_long_description: 'Alarm Register Long Description',
         settings_menu_title: 'Settings',
         settings_menu_clients_title: 'Clients',
         settings_menu_admins_title: 'Admins',
         settings_menu_3scale_admins_title: '3Scale admins',
         settings_menu_3scale_clients_title: '3Scale clients',
         settings_menu_diagnostics_title: 'Diagnostics',
         settings_menu_formulas_title: 'Formulas',
         settings_menu_flows_title: 'Flows',
         settings_menu_categories_title: 'Categories',
         settings_menu_firmwares_title: 'Firmware',
         settings_menu_appliance_types_title: 'Appliance types',
         settings_menu_notifications_title: 'Notifications',
         settings_menu_roles_title: 'Roles',
         settings_menu_modules_title: 'Modules',
         settings_menu_permissions_title: 'Permissions',
         settings_menu_go_back_button: 'Go Back',
         audits_device_name_filter_label: 'Device naam',
         device_single_documentation_parts_button: 'Onderdelen',
         device_single_documentation_lab_vision_button: 'Labvision',
         device_single_documentation_manuals_button: 'Handleiding',
         device_single_documentation_incident_button: 'Storingsleutel',
         navbar_avg_proof_switch: 'Privacy switch',
         clients_filter_bulk_delete_button: 'Delete',
         clients_bulk_delete_no_clients_with_zero_devices_is_selected_error_message:
           'Please select atleast one record with 0 devices',
         clients_bulk_delete_clients_with_devices_count_is_selected_error_message:
           'Please select only those records which have 0 devices',
         projects_filter_bulk_delete_button: 'Delete',
         projects_bulk_delete_no_projects_with_zero_devices_is_selected_error_message:
           'Please select atleast one record with 0 devices',
         projects_bulk_delete_projects_with_devices_count_is_selected_error_message:
           'Please select only those records which have 0 devices',
         projects_bulk_deleted_message:
           '{{ PROJECTS_COUNT }} Projects are deleted successfully',
         projects_delete_modal_title: 'Are you sure?',
         projects_delete_modal_subtitle: 'You want to delete Records?',
         projects_delete_modal_cancel_button: 'Cancel',
         projects_delete_modal_delete_button: 'Delete',
         users_delete_modal_title: 'Are you sure?',
         users_delete_modal_subtitle: 'You want to delete Records?',
         users_delete_modal_cancel_button: 'Cancel',
         users_delete_modal_delete_button: 'Delete',
         users_bulk_delete_no_user_is_selected_error_message:
           'Please select records to delete',
         users_bulk_deleted_message:
           '{{ USERS_COUNT }} Users are deleted successfully',
         service_client_single_filter_bulk_delete_button: 'Delete',
         service_client_single_no_user_selected_for_deleted_message:
           'Please select records to delete',
         admins_bulk_deleted_message:
           '{{ USERS_COUNT }} Admins are deleted successfully',
         admins_no_user_selected_for_deleted_message:
           'Please select records to deleted',
         admins_filter_bulk_delete_button: 'Delete',
         three_scale_admins_bulk_deleted_message:
           '{{ USERS_COUNT }} Three Admins are deleted successfully',
         audits_message_type_label: 'Message Type',
         audit_bulk_export_file_name: 'Audits File',
         registers_reporting_details_all_tab: 'Alle',
         registers_reporting_details_pending_tab: 'Bezig',
         registers_reporting_details_success_tab: 'Succesvol',
         registers_reporting_details_failure_tab: 'Mislukt',
         devices_form_is_service_device_field: 'Service Device',
         swapping_device_missing_new_device_error:
           'New Device is not imported in the Mercurius database',
         device_single_dashboard_mbus_title: 'MBus',
         device_single_dashboard_tab: 'Dashboard',
         device_single_offline_detailed_message: 'Device is "Offline"',
         device_single_online_detailed_message:
           'Device is "Online", everything is working fine',
         device_single_error_detailed_message: 'Device is in "Error" mode',
         device_single_status_documents_link: 'Device Documents',
         email_device_delete_subscription_title:
           'Fortes Data Management - Delete Subscription',
         email_device_delete_subscription_api_status_title:
           'Maxitel API Response:',
         email_device_delete_subscription_type_swapped_device_message:
           'Device subscription is deleted in Device Swapping module',
         email_device_delete_subscription_type_clear_device_message:
           'Device subscription is deleted in Device Clear module',
         devices_form_subscription_id_field: 'Subscription Id',
         device_single_rss_sim_status: 'SIM Status',
         device_monitoring_error_list_table_title: 'Error List',
         device_monitoring_error_list_table_device: 'Device',
         device_monitoring_error_list_table_alarm_code: 'Alarm Code',
         device_monitoring_error_list_table_start_date: 'Start Date',
         device_monitoring_error_list_table_end_date: 'End Date',
         device_monitoring_data_consumed_list_table_title: 'Data Consumed',
         device_monitoring_data_consumed_list_table_device: 'Device',
         device_monitoring_data_consumed_list_table_data_usage: 'Data Usage',
         device_monitoring_data_consumed_list_table_date: 'Date',
         device_monitoring_ota_history_list_table_title: 'OTA History',
         device_monitoring_ota_history_list_table_device: 'Device',
         device_monitoring_ota_history_list_table_status: 'Status',
         device_monitoring_ota_history_list_table_date: 'Date',
         device_monitoring_device_status_history_title: 'Device Status History',
         device_monitoring_device_status_history_hr: 'Hr',
         device_monitoring_device_status_history_no_graph_data_error:
           'The graph is unavailable',
         device_monitoring_device_status_history_graph_loading_data:
           'Loading graph',
         device_monitoring_firmware_history_title: 'Firmware History',
         device_monitoring_device_firmware_history_graph_loading_data:
           'Loading barchart',
         device_monitoring_device_firmware_history_no_graph_data_error:
           'The barchart is unavailable',
         sidebar_devices_monitoring: 'Devices Monitoring',
         device_monitoring_projects_counter_title: 'Projects Counter',
         device_monitoring_device_projects_counter_graph_loading_data:
           'Loading barchart',
         device_monitoring_device_projects_counter_no_graph_data_error:
           'The barchart is unavailable',
         device_monitoring_users_counter_title: 'Users Counter',
         device_monitoring_device_users_counter_graph_loading_data:
           'Loading barchart',
         device_monitoring_device_users_counter_no_graph_data_error:
           'The barchart is unavailable',
         device_monitoring_sim_counter_title: 'SIM Data Counter',
         device_monitoring_sim_counter_exceeded_data_title: 'Exceeded',
         device_monitoring_sim_counter_in_package_data_title:
           'In Package Limit',
         device_monitoring_device_sim_counter_graph_loading_data:
           'Loading barchart',
         device_monitoring_device_sim_counter_no_graph_data_error:
           'The barchart is unavailable',
         device_monitoring_device_error_counters_title: 'Error Counters',
         device_monitoring_device_error_counters_error: 'No data found',
         device_monitoring_device_error_counters_no_graph_data_error:
           'The piechart is unavailable',
         device_monitoring_date_range_field: 'Date Range',
         reporting_ota_details_retries_tab: 'Retries',
         device_monitoring_single_page_title: 'Device Monitoring',
         device_monitoring_ota_history_list_table_fail_counter: 'Retries',
         device_monitoring_ota_history_list_table_user: 'User',
         device_monitoring_ota_history_list_table_message: 'Message',
         device_monitoring_ota_history_list_table_old_firmware_version:
           'Old Firmware Version',
         device_monitoring_project_list_table_title: 'Projects',
         device_monitoring_firmware_list_table_title: 'Firmwares',
         device_monitoring_firmware_list_table_firmware_version: 'Firmware',
         device_monitoring_firmware_list_table_device: 'Device',
         device_monitoring_firmware_list_table_project: 'Project',
         device_monitoring_firmware_list_table_saved_at: 'Date',
         monitoring_users_list_table_title: 'Users',
         monitoring_3scale_users_list_table_title: 'Three Scale Users',
         monitoring_3scale_users_filter_3scale_admin: '3Scale Admin',
         monitoring_3scale_users_filter_3scale_client: '3Scale Client',
         client_single_users_table_role: 'Role',
         device_monitoring_device_status_list_table_title: 'Device Status',
         navbar_languages_english: 'English',
         navbar_languages_dutch: 'Nederlands',
         navbar_languages_german: 'Deutsch',
         device_single_device_settings_tab: 'Settings v2',
         device_single_device_settings_title: 'Device v2 Settings',
         device_single_device_settings_secondary_device_type_field:
           'Secondary Device Type',
         device_single_device_settings_base_topic_field: 'Base Topic',
         device_single_device_settings_baud_rate_uart_field: 'Baud Rate UART',
         device_single_device_settings_select_baud_rate_uart_field:
           'Select Baud Rate UART',
         device_single_device_settings_modbus_error_register_address_field:
           'Modbus error register address',
         device_single_device_settings_modbus_error_register_value_field:
           'Modbus expected error register value',
         device_single_device_settings_reboot_device_v2: 'Reboot',
         device_single_device_settings_send_button_v2: 'Send',
         device_single_device_settings_message_forwarded:
           'Message is forwarded to device',
         device_single_device_settings_secondary_device_type_error:
           'Secondary Device Type must be between 1 and 254',
         device_single_device_settings_base_topic_error:
           'Base Topic length must be between 5 and 50',
         device_single_device_settings_baud_rate_uart_error:
           'Please select a option for Baud Rate UART',
         device_single_device_settings_modbus_error_register_address_error:
           'Modbus error register address must be between 0 and 65535',
         device_single_device_settings_modbus_error_register_value_error:
           'Modbus expected error register value must be between 0 and 65535',
         device_monitoring_device_error_counters_loading_data:
           'Loading piechart',
         diagnostic_formulas_form_diagnostic_formulas_name_de_field:
           'Name (DE)',
         diagnostic_formulas_name_de_required_form_error:
           'Name (DE) is required',
         diagnostic_formulas_form_description_de_field: 'Description (DE)',
         diagnostic_flows_form_diagnostic_flows_name_de_field: 'Name (DE)',
         diagnostic_flows_name_de_required_form_error: 'Name (DE) is required',
         diagnostic_flows_form_description_de_field: 'Description (DE)',
         diagnostic_categories_form_diagnostic_categories_name_de_field:
           'Name (DE)',
         diagnostic_categories_name_de_required_form_error:
           'Name (DE) is required',
         diagnostic_categories_form_description_de_field: 'Description (DE)',
         diagnostic_categories_table_name_de: 'Name (DE)',
         diagnostic_flows_table_name_de: 'Name (DE)',
         diagnostic_formulas_table_name_de: 'Name (DE)',
         modules_form_module_name_de_field: 'Name (DE)',
         modules_name_de_required_form_error: 'Name (DE) is required',
         modules_form_description_de_field: 'Description (DE)',
         modules_table_name_de: 'Name (DE)',
         modules_table_description_de: 'Description (DE)',
         roles_form_role_name_de_field: 'Name (DE)',
         roles_name_de_required_form_error: 'Name (DE) is required',
         roles_form_description_de_field: 'Description (DE)',
         roles_table_name_de: 'Name (DE)',
         roles_table_description_de: 'Description (DE)',
         permissions_form_permission_name_de_field: 'Name (DE)',
         permissions_form_description_de_field: 'Description (DE)',
         permissions_table_name_de: 'Name (DE)',
         permissions_table_description_de: 'Description (DE)',
         notifications_form_title_de_field: 'Title (DE)',
         notifications_form_description_de_field: 'Description (DE)',
         firmwares_name_de_required_form_error: 'Name (DE)',
         firmwares_form_firmware_name_de_field: 'Name (DE) is required',
         navbar_languages_danish: 'Danish',
         firmwares_name_dk_required_form_error: 'Name (DK)',
         firmwares_form_firmware_name_dk_field: 'Name (DK) is required',
         diagnostic_formulas_form_diagnostic_formulas_name_dk_field:
           'Name (DK)',
         diagnostic_formulas_name_dk_required_form_error:
           'Name (DK) is required',
         diagnostic_formulas_form_description_dk_field: 'Description (DK)',
         diagnostic_flows_form_diagnostic_flows_name_dk_field: 'Name (DK)',
         diagnostic_flows_name_dk_required_form_error: 'Name (DK) is required',
         diagnostic_flows_form_description_dk_field: 'Description (DK)',
         diagnostic_categories_form_diagnostic_categories_name_dk_field:
           'Name (DK)',
         diagnostic_categories_name_dk_required_form_error:
           'Name (DK) is required',
         diagnostic_categories_form_description_dk_field: 'Description (DK)',
         diagnostic_categories_table_name_dk: 'Name (DK)',
         diagnostic_flows_table_name_dk: 'Name (DK)',
         diagnostic_formulas_table_name_dk: 'Name (DK)',
         modules_form_module_name_dk_field: 'Name (DK)',
         modules_name_dk_required_form_error: 'Name (DK) is required',
         modules_form_description_dk_field: 'Description (DK)',
         modules_table_name_dk: 'Name (DK)',
         modules_table_description_dk: 'Description (DK)',
         roles_form_role_name_dk_field: 'Name (DK)',
         roles_name_dk_required_form_error: 'Name (DK) is required',
         roles_form_description_dk_field: 'Description (DK)',
         roles_table_name_dk: 'Name (DK)',
         roles_table_description_dk: 'Description (DK)',
         permissions_form_permission_name_dk_field: 'Name (DK)',
         permissions_form_description_dk_field: 'Description (DK)',
         permissions_table_name_dk: 'Name (DK)',
         permissions_table_description_dk: 'Description (DK)',
         notifications_form_title_dk_field: 'Title (DK)',
         notifications_form_description_dk_field: 'Description (DK)',
         device_monitoring_data_consumed_list_table_project: 'Project',
         project_single_device_list_search_mbus_registers_by_project:
           'Search Mbus Registers',
         project_single_fetch_mbus_registers_by_project_success_message:
           'Message is forwarded to Project ##PROJECT_NAME## Devices successfully',
         project_single_fetch_mbus_registers_by_project_error_message:
           'Interval Server Error',
         devices_list_diagnostic_filters_title: 'Registers Filter',
         devices_list_diagnostics_condition_modal_sensor_type_label:
           'Sensor Type',
         devices_list_diagnostics_condition_modal_modbus_label: 'Modbus Sensor',
         devices_list_diagnostics_condition_modal_mbus_label: 'Mbus Sensor',
         devices_list_diagnostics_condition_modal_formula_output_label:
           'Formula Output',
         devices_list_diagnostics_condition_modal_meter_type_label:
           'Meter Type',
         devices_list_diagnostics_condition_modal_meter_type_label_default_option:
           'Select Meter Type',
         devices_list_diagnostics_condition_modal_operator_label: 'Operator',
         devices_list_diagnostics_condition_modal_operator_option_1: 'Less',
         devices_list_diagnostics_condition_modal_operator_option_2:
           'Less than equal to',
         devices_list_diagnostics_condition_modal_operator_option_3: 'Equal to',
         devices_list_diagnostics_condition_modal_operator_option_4: 'Greater',
         devices_list_diagnostics_condition_modal_operator_option_5:
           'Greater than equal to',
         devices_list_diagnostics_condition_modal_operator_option_6:
           'Not Equal to',
         devices_list_diagnostics_condition_modal_register_label: 'Register',
         devices_list_diagnostics_condition_modal_register_required_error:
           'Register is required',
         devices_list_diagnostics_send_message_modal_registers_label_default_option:
           'Select Register',
         devices_list_diagnostics_condition_modal_value_label: 'Value',
         devices_list_diagnostics_condition_modal_value_is_required_error:
           'Value is required',
         devices_list_diagnostics_condition_modal_operator_is_required_error:
           'Operator is required',
         devices_list_diagnostics_condition_modal_meter_is_required_error:
           'Meter is required',
         devices_list_diagnostics_condition_modal_value_invalid_error:
           'Invalid Value, please check',
         devices_list_diagnostics_condition_modal_filter_button: 'Filter',
         devices_list_diagnostics_condition_modal_close_button: 'Close',
         devices_list_diagnostics_condition_modal_add_button:
           'Add more filters',
         error_check_table_title: 'Device Error Checks',
         error_check_table_filter_search: 'Search',
         error_check_table_no_record_found: 'No Records Found',
         error_check_table_row_per_page: 'Rows Per Page',
         error_check_table_created_at: 'Created At',
         error_check_table_total_devices: 'Total Devices',
         error_check_details_table_title: 'Device Error Check Details',
         error_check_details_table_filter_search: 'Search',
         error_check_details_table_no_record_found: 'No Records Found',
         error_check_details_table_row_per_page: 'Rows Per Page',
         error_check_details_table_created_at: 'Created At',
         error_check_details_table_device: 'Device',
         error_check_details_table_old_error: 'Old Error',
         error_check_details_table_new_error: 'New Error',
         error_check_details_table_status: 'Status',
         error_check_details_table_status_success: 'Success',
         error_check_details_table_status_failed: 'Failure',
         error_check_details_table_all_tab: 'All',
         error_check_details_table_success_tab: 'Success',
         error_check_details_table_failure_tab: 'Failure',
         error_check_details_table_device_name: 'Device Name',
         error_check_details_table_device_serial_number: 'Serial Number',
         sidebar_devices_error_check: 'Error Devices',
         error_check_details_table_bulk_export_file_name:
           'Error Devices Details List',
         error_check_table_bulk_export_file_name: 'Error Devices List',
         device_single_registers_table_min_error:
           'Value must be greater than or equal to min_value',
         device_single_registers_table_invalid_value_error:
           'Please enter a valid number',
         project_single_project_setting_tab: 'Project Setting',
         project_single_project_setting_mbus_registers_title: 'MBus Registers',
         project_single_project_setting_mbus_register_accumulated_heating_energy_title:
           'Accumulated heating energy Unit',
         project_single_project_setting_mbus_register_accumulated_heating_energy_success_message:
           'Accumulated heating energy unit is successfully updated',
         project_single_project_setting_mbus_register_accumulated_heating_energy_error_message:
           'Failed to update Accumulated heating energy unit',
         project_single_project_setting_select_mbus_register_accumulated_heating_energy_title:
           'Select Accumulated heating energy',
         project_single_project_setting_select_mbus_register_update_button:
           'Update',
         custom_popover_100_short_description: 'Verwarming status',
         custom_popover_101_short_description: 'Tapwater status',
         custom_popover_102_short_description: 'Warmhoud status',
         custom_popover_103_short_description: 'Anti Legionella status',
         custom_popover_104_short_description: 'Koelen status',
         custom_popover_1802_short_description: 'Elektrisch element',
         custom_popover_105_short_description: 'Alarm',
         custom_popover_106_short_description: 'Software versie',
         custom_popover_107: 'Tapwater regelafsluiter (M1)',
         custom_popover_108: 'Verwarmen/koelen regelafsluiter (M2)',
         custom_popover_109_short_description: 'Circulatie pomp',
         custom_popover_110_short_description: '6-weg kogelafsluiter',
         custom_popover_113_short_description: 'Ruimte thermostaat',
         custom_popover_114_short_description: 'OpenTherm Indicatie',
         custom_popover_200_short_description: 'Volumestroom tapwater',
         custom_popover_201_short_description: 'T-Tapwater',
         custom_popover_202_short_description: 'T-Primaire aanvoer',
         custom_popover_203_short_description:
           'T-Primaire retour (T-Booster voor TE-Booster toestellen)',
         custom_popover_205_short_description: 'T-Secundaire aanvoer',
         custom_popover_206_short_description: 'T-Secundaire retour',
         custom_popover_204_short_description:
           'Berekende aanvoertemperatuur (setpoint)',
         custom_popover_207_short_description: 'Ruimte temperatuur(OpenTherm)',
         custom_popover_208_short_description: 'Ruimte setpoint (OpenTherm)',
         custom_popover_210_short_description:
           'Waterdruk in de binnenhuisinstallatie',
         custom_popover_800_short_description: 'Totale bedrijfstijd afleverset',
         custom_popover_801_short_description:
           'Totale bedrijfstijd warmte-vraag',
         custom_popover_802_short_description:
           'Totale bedrijfstijd koude-vraag',
         custom_popover_803_short_description:
           'Totale bedrijfstijd tapwater-vraag',
         custom_popover_804_short_description: 'Aantal tappingen',
         custom_popover_805_short_description:
           'Totale volume tapwater (let op! dit is een indicatie)',
         custom_popover_806_short_description: 'Stroomonderbrekingen',
         create_role_stepper_information_title: 'Information',
         create_role_stepper_client_title: 'Client',
         create_role_stepper_service_client_title: 'Service Client',
         create_role_stepper_project_title: 'Project',
         create_role_stepper_device_management_title: 'Device Management',
         create_role_stepper_commissioning_title: 'Commissioning',
         create_role_stepper_reporting_title: 'Reporting',
         create_role_stepper_general_title: 'General',
         roles_form_role_name_field: 'Naam',
         roles_name_required_form_error: 'Naam is verplicht',
         roles_form_description_field: 'Omschrijving',
         create_role_stepper_back_button: 'Back',
         create_role_stepper_next_button: 'Next',
         create_role_stepper_finish_button: 'Finish',
         create_role_stepper_yes_label: 'Yes',
         create_role_stepper_no_label: 'No',
         create_role_stepper_step_2_client_view_all_users: 'View all users?',
         create_role_stepper_step_2_client_create_new_users:
           'Create new users?',
         create_role_stepper_step_2_client_edit_new_users:
           'Modify existing users like the role?',
         create_role_stepper_step_3_service_client_create_new:
           "Create a new 'service client'?",
         create_role_stepper_step_3_service_client_view_users:
           "View existing 'service client' users?",
         create_role_stepper_step_3_service_client_create_users:
           "Create new 'service client' users?",
         create_role_stepper_step_3_service_client_modify_users:
           "Modify 'service client' users like the role?",
         create_role_stepper_step_4_project_view: 'View projects?',
         create_role_stepper_step_4_project_create_new: 'Create new projects?',
         create_role_stepper_step_4_project_export_mbus_data:
           'Export energy meter data from projects?',
         create_role_stepper_step_5_device_view_single_page: 'Open a device?',
         create_role_stepper_step_5_device_view_single_page_setting_registers:
           'View settings?',
         create_role_stepper_step_5_device_edit_single_page_setting_registers:
           'Modify settings?',
         create_role_stepper_step_5_device_edit_single_page_manual_control:
           'Use manual control?',
         create_role_stepper_step_5_device_single_page_view_history:
           'View history?',
         create_role_stepper_step_5_device_single_page_view_meter_data:
           'View meter data?',
         create_role_stepper_step_5_device_single_page_use_diagnostics_module:
           'Use the diagnostics module?',
         create_role_stepper_step_5_device_single_page_information_and_delivery_set_data:
           'View information such as device info, address details, and delivery set data?',
         create_role_stepper_step_6_commissioning_module_put_devices_into_operation:
           'Commissioning module to put devices into operation?',
         create_role_stepper_step_6_commissioning_module_swapping:
           'Exchange devices (transfer data from old device to new device)?',
         create_role_stepper_step_7_reporting_audit:
           'The logbook that contains all messages, errors, and actions on devices?',
         create_role_stepper_step_7_reporting_swapped_devices:
           'Logbook of exchanged devices?',
         create_role_stepper_step_7_reporting_error_devices:
           'Logbook of devices with faults?',
         create_role_stepper_step_7_reporting_bulk_registers_update:
           'Results of group changes?',
         create_role_stepper_step_8_view_dashboard:
           'Dashboard all access and sidebar device counters',
         create_role_stepper_step_8_general_create_role: 'Creating roles?',
         create_role_stepper_question_required_error:
           'One option is required to proceed',
         roles_form_role_name_en_field: 'Naam (EN)',
         roles_form_role_name_nl_field: 'Naam (NL)',
         roles_name_en_required_form_error: 'Naam (EN) is verplicht',
         roles_name_nl_required_form_error: 'Naam (NL) is verplicht',
         roles_form_description_en_field: 'Omschrijving (EN)',
         roles_form_description_nl_field: 'Omschrijving (NL)',
         project_single_project_setting_device_v2_settings_title:
           'Device V2 Settings',
         project_single_device_settings_secondary_device_type_field:
           'Secondary Device Type',
         project_single_project_setting_base_topic_field: 'Base Topic',
         project_single_device_settings_baud_rate_uart_field: 'Baud Rate UART',
         project_single_device_settings_select_baud_rate_uart_field:
           'Select Baud Rate UART',
         project_single_device_settings_modbus_error_register_address_field:
           'Modbus error register address',
         project_single_device_settings_modbus_error_register_value_field:
           'Modbus expected error register value',
         project_single_project_setting_device_v2_send_button: 'Send',
         project_single_project_setting_base_topic_success_message:
           'Base Topic is successfully forwarded to ##SENT_MESSAGE_TO_DEVICES_COUNTER## devices of Project',
         project_single_project_setting_secondary_device_type_success_message:
           'Secondary Device Type is successfully forwarded to ##SENT_MESSAGE_TO_DEVICES_COUNTER## devices of Project',
         project_single_project_setting_reboot_message:
           'Device Reboot Message is successfully forwarded to ##SENT_MESSAGE_TO_DEVICES_COUNTER## devices of Project',
         project_single_project_setting_baut_rate_uart_success_message:
           'Device Baut Rate UART Message is successfully forwarded to ##SENT_MESSAGE_TO_DEVICES_COUNTER## devices of Project',
         project_single_project_setting_modbus_expected_error_register_value_success_message:
           'Device Modbus Expected Error Register Value Message is successfully forwarded to ##SENT_MESSAGE_TO_DEVICES_COUNTER## devices of Project',
         project_single_project_setting_modbus_error_register_address_success_message:
           'Device Reboot Message is successfully forwarded to ##SENT_MESSAGE_TO_DEVICES_COUNTER## devices of Project',
         docs_third_tab_video_instruction: 'Video Instruction',
         docs_third_tab_video_instruction_title: 'Video Instruction',
         custom_popover_106_long_description: 'Software versie',
         EXPORT_MBUS_METER_SERIAL_NUMBER: 'Serienummer meter',
         navbar_languages_french: 'French',
         diagnostic_formulas_form_diagnostic_formulas_name_fr_field:
           'Name (FR)',
         diagnostic_formulas_name_fr_required_form_error:
           'Name (FR) is required',
         diagnostic_formulas_form_description_fr_field: 'Description (FR)',
         diagnostic_flows_form_diagnostic_flows_name_fr_field: 'Name (FR)',
         diagnostic_flows_name_fr_required_form_error: 'Name (FR) is required',
         diagnostic_flows_form_description_fr_field: 'Description (FR)',
         diagnostic_categories_form_diagnostic_categories_name_fr_field:
           'Name (FR)',
         diagnostic_categories_name_fr_required_form_error:
           'Name (FR) is required',
         diagnostic_categories_form_description_fr_field: 'Description (FR)',
         modules_form_module_name_fr_field: 'Name (FR)',
         modules_name_fr_required_form_error: 'Name (FR) is required',
         modules_form_description_fr_field: 'Description (FR)',
         roles_form_role_name_fr_field: 'Name (FR)',
         roles_name_fr_required_form_error: 'Name (FR) is required',
         roles_form_description_fr_field: 'Description (FR)',
         permissions_form_permission_name_fr_field: 'Name (FR)',
         permissions_form_description_fr_field: 'Description (FR)',
         firmwares_form_firmware_name_fr_field: 'Name (FR)',
         firmwares_name_fr_required_form_error: 'Name (FR) is required',
         notifications_form_title_fr_field: 'Name (FR)',
         notifications_form_description_fr_field: 'Description (FR)',
         notifications_title_dk_required_form_error: 'Name (DK) is required',
         notifications_title_fr_required_form_error: 'Name (FR) is required',
         notifications_title_de_required_form_error: 'Name (DE) is required',
         notifications_description_dk_required_form_error:
           'Description (DK) is required',
         notifications_description_de_required_form_error:
           'Description (DE) is required',
         notifications_description_fr_required_form_error:
           'Description (FR) is required',
         client_single_error_devices_bread_crumb: 'Error Devices',
         service_client_single_error_devices_bread_crumb: 'Error Devices',
         devices_list_diagnostics_condition_modal_fetch_and_filter_button:
           'Fetch & Filter',
         diagnostic_register_drawer_selected_devices_title: 'Selected Devices',
         diagnostic_register_drawer_fetch_and_filter_message_1:
           'Fetching devices data',
         diagnostic_register_drawer_fetch_and_filter_message_2:
           'Database refresh',
         diagnostic_register_drawer_fetch_and_filter_message_3:
           'Filtering devices',
         devices_bulk_mbus_download_selected_devices_title: 'Devices Selected',
         custom_popover_100_long_description: 'Verwarming status',
         custom_popover_101_long_description: 'Tapwater status',
         custom_popover_102_long_description: 'Warmhoud status',
         custom_popover_103_long_description: 'Anti Legionella status',
         custom_popover_104_long_description: 'Koelen status',
         custom_popover_1802_long_description: 'Elektrisch element',
         custom_popover_109_long_description: 'Circulatie pomp',
         custom_popover_110_long_description: '6-weg kogelafsluiter',
         custom_popover_113_long_description: 'Ruimte thermostaat',
         custom_popover_114_long_description: 'OpenTherm Indicatie',
         custom_popover_200_long_description: 'Volumestroom tapwater',
         custom_popover_201_long_description: 'T-Tapwater',
         custom_popover_202_long_description: 'T-Primaire aanvoer',
         custom_popover_203_long_description:
           'T-Primaire retour (T-Booster voor TE-Booster toestellen)',
         custom_popover_205_long_description: 'T-Secundaire aanvoer',
         custom_popover_206_long_description: 'T-Secundaire retour',
         custom_popover_204_long_description:
           'Berekende aanvoertemperatuur (setpoint)',
         custom_popover_207_long_description: 'Ruimte temperatuur(OpenTherm)',
         custom_popover_208_long_description: 'Ruimte setpoint (OpenTherm)',
         custom_popover_210_long_description:
           'Waterdruk in de binnenhuisinstallatie',
         custom_popover_800_long_description: 'Totale bedrijfstijd afleverset',
         custom_popover_801_long_description:
           'Totale bedrijfstijd warmte-vraag',
         custom_popover_802_long_description: 'Totale bedrijfstijd koude-vraag',
         custom_popover_803_long_description:
           'Totale bedrijfstijd tapwater-vraag',
         custom_popover_804_long_description: 'Aantal tappingen',
         custom_popover_805_long_description:
           'Totale volume tapwater (let op! dit is een indicatie)',
         custom_popover_806_long_description: 'Stroomonderbrekingen',
         project_single_project_setting_mbus_hourly_logs_title:
           'Mbus Hourly Logs',
         project_single_project_setting_mbus_hourly_logs_field:
           'Fetch Mbus Hourly',
         project_single_project_setting_mbus_hourly_logs_update_button:
           'Update',
         project_single_project_setting_mbus_hourly_logs_success_message:
           'Mbus Hourly logs are successfully set for the project',
         project_single_project_setting_mbus_hourly_logs_error_message:
           'Failed to update Mbus Hourly logs for the project',
         sidebar_mbus_reporting: 'Mbus Reporting',
         mbus_reporting_table_device: 'Device',
         mbus_reporting_table_project: 'Project',
         mbus_reporting_table_created_at: 'Created At',
         mbus_reporting_table_viewed_by: 'Viewed By',
         mbus_reporting_table_viewed_at: 'Viewed At',
         mbus_reporting_table_firmware: 'Firmware',
         mbus_reporting_table_no_record_found: 'No Records Found',
         mbus_reporting_table_all_filter: 'All',
         mbus_reporting_table_read_filter: 'Read',
         mbus_reporting_table_unread_filter: 'Unread',
         mbus_reporting_table_row_per_page: 'Rows per page',
         mbus_reporting_table_title: 'Mbus Reporting',
         mbus_reporting_anomaly_table_title: 'Mbus Anomalies',
         mbus_reporting_anomaly_table_difference: 'Difference',
         mbus_reporting_anomaly_table_detection: 'Detection',
         mbus_reporting_anomaly_table_mbus_meter_type: 'Meter',
         mbus_reporting_anomaly_table_mbus_serial_number: 'Mbus Serial Number',
         mbus_reporting_anomaly_table_mbus_old_value: 'Old Value',
         mbus_reporting_anomaly_table_mbus_register: 'Register',
         mbus_reporting_anomaly_table_mbus_reason: 'Reason',
         mbus_reporting_anomaly_table_mbus_fail_reason: 'Greater than',
         mbus_reporting_anomaly_table_mbus_suspicious_reason: 'Lies in between',
         mbus_reporting_anomaly_table_fail: 'Fail',
         mbus_reporting_anomaly_table_suspicious: 'Suspicious',
         mbus_reporting_table_bulk_view_reports_success_message:
           'Mbus Reporting Messages are successfully viewed',
         mbus_reporting_table_bulk_view_reports_error_message:
           'Please select Mbus Reporting records to proceed',
         mbus_reporting_table_bulk_view_button: 'Bulk View',
         mbus_reporting_table_view_button: 'View',
         mbus_reporting_table_filters_project_field: 'Project',
         mbus_reporting_table_filters_device_name_field: 'Device',
         mbus_reporting_table_filters_start_date: 'Start Date',
         mbus_reporting_table_filters_end_date: 'End Date',
         mbus_reporting_table_filters_firmware: 'Firmware',
         mbus_reporting_table_filters_mbus_serial_number: 'Mbus Serial Number',
         mbus_reporting_table_filters_viewed_by: 'Viewed By',
         business_situation_measured_values_209: 'Outdoor Temperature',
         custom_popover_209_short_description: 'Outdoor Temperature',
         custom_popover_209_long_description: 'Outdoor Temperature',
         appliance_types_set_registers_table_dashboard: 'Dashboard',
         appliance_types_set_registers_table_dashboard_error_message:
           'Minimum 0 and Maximum 6 Dashboard Registers are allowed for device dashboard',
         sidebar_missing_hourly_reporting: 'Mbus Missing Data Report',
         missing_hourly_reporting_table_title:
           'Mbus Hourly Missing Data Reporting',
         mbus_hourly_missing_reporting_table_filters_project_field: 'Project',
         mbus_hourly_missing_reporting_table_filters_device_name_field:
           'Device',
         mbus_hourly_missing_reporting_table_filters_firmware_field: 'Firmware',
         mbus_hourly_missing_reporting_table_last_seen: 'Mbus Message At',
         mbus_hourly_missing_reporting_table_created_at: 'Created At',
         mbus_hourly_missing_reporting_table_device: 'Device',
         mbus_hourly_missing_reporting_table_project: 'Project',
         mbus_hourly_missing_reporting_table_firmware: 'Firmware',
         mbus_hourly_missing_reporting_table_no_record_found:
           'No Records Found',
         mbus_hourly_missing_reporting_table_row_per_page: 'Rows per page',
         device_single_dashboard_registers_hot_water_tab: 'Hot Water',
         device_single_dashboard_registers_heating_tab: 'Heating',
         device_single_dashboard_registers_cooling_tab: 'Cooling',
         device_single_documentation_instructie_pomp_button: 'Instructie Pomp',
         // General Modbus Control / Status registers(90-99) - TR04
         general_modbus_control_TR04_80: 'Firmware Image status',
         general_modbus_control_TR04_81: 'Firmware Image ID',
         general_modbus_control_TR04_82: 'Defaults Image status',
         general_modbus_control_TR04_83: 'Defaults Image Checksum',
         general_modbus_control_TR04_96: 'Modbus Version',
         general_modbus_control_TR04_97: 'Modbus Device type / table',
         general_modbus_control_TR04_98: 'Unit selection ',
         general_modbus_control_TR04_99: 'Control register ',

         // Operating Status - TR04
         business_situation_operating_status_TR04_title:
           'Operating state Heat Interface Unit',
         business_situation_operating_status_TR04_100: 'CH Control state',
         business_situation_operating_status_TR04_100_list: JSON.stringify({
           list: {
             0: 'Idle',
             1: 'Request',
             2: 'Demand',
             3: 'Post Circulation',
             4: 'Off',
             5: 'Pre Circulation',
           },
         }),
         business_situation_operating_status_TR04_101: 'DHW Control State',
         business_situation_operating_status_TR04_101_list: JSON.stringify({
           list: {
             0: 'Idle',
             1: 'Request',
             2: 'Demand',
             3: 'Post Circulation',
             4: 'Off',
           },
         }),
         business_situation_operating_status_TR04_102: 'Preheat Control State',
         business_situation_operating_status_TR04_102_list: JSON.stringify({
           list: {
             0: 'Idle',
             1: 'Request',
             2: 'Demand',
           },
         }),
         business_situation_operating_status_TR04_103:
           'Anti-Legionella control state',
         business_situation_operating_status_TR04_103_list: JSON.stringify({
           list: {
             0: 'Idle',
             1: 'Request',
             2: 'Demand',
             3: 'Post Circulation',
             4: 'Off',
           },
         }),
         business_situation_operating_status_TR04_104: 'Cooling Control state',
         business_situation_operating_status_TR04_104_list: JSON.stringify({
           list: {
             0: 'Idle',
             1: 'Request',
             2: 'Init',
             3: 'Demand',
             4: 'Post Circulation',
             5: 'Pre Circulation',
             6: 'Init',
           },
         }),

         business_situation_operating_status_TR04_105: 'Error Code',
         business_situation_operating_status_TR04_106: 'Software version',
         business_situation_operating_status_TR04_107:
           'StepperMotor1 Actual Position',
         business_situation_operating_status_TR04_108:
           'StepperMotor2 Actual Position',
         business_situation_operating_status_TR04_109: 'Pump',
         business_situation_operating_status_TR04_109_list: JSON.stringify({
           list: {
             0: 'Off',
             1: 'On',
           },
         }),
         business_situation_operating_status_TR04_110: 'Valve1',
         business_situation_operating_status_TR04_110_list: JSON.stringify({
           list: {
             0: 'Position: Heating',
             1: 'Position: Cooling',
           },
         }),
         business_situation_operating_status_TR04_112_list: JSON.stringify({
           list: {
             0: 'Heating or demand',
             1: 'Cooling',
           },
         }),
         business_situation_operating_status_TR04_113: 'Room thermostat input',
         business_situation_operating_status_TR04_113_list: JSON.stringify({
           list: {
             0: 'Open',
             1: 'Closed',
           },
         }),
         business_situation_operating_status_TR04_114: 'OpenTherm Indicator',
         business_situation_operating_status_TR04_114_list: JSON.stringify({
           list: {
             0: 'Inactive',
             1: 'Active',
           },
         }),
         business_situation_operating_status_TR04_115: 'Appliance Type',

         // Business situation - TR04
         business_situation_measured_values_TR04_title: 'Measurements',
         business_situation_measured_values_TR04_200: 'Flow rate',
         business_situation_measured_values_TR04_201: 'DHW Out temperature',
         business_situation_measured_values_TR04_202: 'Primary supply',
         business_situation_measured_values_TR04_203: 'Primary return',
         business_situation_measured_values_TR04_205: 'Secondary CH Supply',
         business_situation_measured_values_TR04_206: 'Secondary CH Return',
         business_situation_measured_values_TR04_204: 'CH calculated setpoint',
         business_situation_measured_values_TR04_207: 'OT Room Temperature',
         business_situation_measured_values_TR04_208: 'OT CH Setpoint',
         business_situation_measured_values_TR04_210: 'Water pressure',
         business_situation_measured_values_TR04_213: 'Primary DHW Return',
         business_situation_measured_values_TR04_214: 'Primary CH Return',

         // Operating time - TR04
         business_situation_operating_time_TR04_title: 'Operating Time',
         business_situation_operating_time_TR04_800: 'System Run Time',
         business_situation_operating_time_TR04_801: 'CH Burn Time',
         business_situation_operating_time_TR04_802: 'Cooling Time',
         business_situation_operating_time_TR04_803: 'DHW Burn Time',
         business_situation_operating_time_TR04_804: 'Number of tappings',

         // Lockout History-TR04
         lockout_time_TR04_title: 'Lockout History',
         lockout_time_TR04_820: 'Lock-out 1',
         lockout_time_TR04_821: 'Lock-out 2',
         lockout_time_TR04_822: 'Lock-out 3',
         lockout_time_TR04_823: 'Lock-out 4',
         lockout_time_TR04_824: 'Lock-out 5',
         lockout_time_TR04_825: 'Lock-out 6',
         lockout_time_TR04_826: 'Lock-out 7',
         lockout_time_TR04_827: 'Lock-out 8',
         lockout_time_TR04_828: 'Lock-out 9',
         lockout_time_TR04_829: 'Lock-out 10',
         lockout_time_TR04_830: 'Lock-out 11',
         lockout_time_TR04_831: 'Lock-out 12',
         lockout_time_TR04_832: 'Lock-out 13',
         lockout_time_TR04_833: 'Lock-out 14',
         lockout_time_TR04_834: 'Lock-out 15',
         lockout_time_TR04_835: 'Lock-out 16',
         lockout_time_TR04_836: 'Lock-out history time 1',
         lockout_time_TR04_837: 'Lock-out history time 2',
         lockout_time_TR04_838: 'Lock-out history time 3',
         lockout_time_TR04_839: 'Lock-out history time 4 ',
         lockout_time_TR04_840: 'Lock-out history time 5 ',
         lockout_time_TR04_841: 'Lock-out history time 6 ',
         lockout_time_TR04_842: 'Lock-out history time 7',
         lockout_time_TR04_843: 'Lock-out history time 8',
         lockout_time_TR04_844: 'Lock-out history time 9',
         lockout_time_TR04_845: 'Lock-out history time 10',
         lockout_time_TR04_846: 'Lock-out history time 11',
         lockout_time_TR04_847: 'Lock-out history time 12',
         lockout_time_TR04_848: 'Lock-out history time 13',
         lockout_time_TR04_849: 'Lock-out history time 14',
         lockout_time_TR04_850: 'Lock-out history time 15',
         lockout_time_TR04_851: 'Lock-out history time 16',
         // Blocking History-TR04
         blocking_history_TR04_860: 'Blocking 1',
         blocking_history_TR04_861: 'Blocking 2',
         blocking_history_TR04_862: 'Blocking 3',
         blocking_history_TR04_863: 'Blocking 4',
         blocking_history_TR04_864: 'Blocking 5',
         blocking_history_TR04_865: 'Blocking 6',
         blocking_history_TR04_866: 'Blocking 7',
         blocking_history_TR04_867: 'Blocking 8',
         blocking_history_TR04_868: 'Blocking 9',
         blocking_history_TR04_869: 'Blocking 10',
         blocking_history_TR04_870: 'Blocking 11',
         blocking_history_TR04_871: 'Blocking 12',
         blocking_history_TR04_872: 'Blocking 13',
         blocking_history_TR04_873: 'Blocking 14',
         blocking_history_TR04_874: 'Blocking 15',
         blocking_history_TR04_875: 'Blocking 16',
         blocking_history_TR04_876: 'Blocking history time 1',
         blocking_history_TR04_877: 'Blocking history time 2',
         blocking_history_TR04_878: 'Blocking history time 3',
         blocking_history_TR04_879: 'Blocking history time 4',
         blocking_history_TR04_880: 'Blocking history time 5',
         blocking_history_TR04_881: 'Blocking history time 6',
         blocking_history_TR04_882: 'Blocking history time 7',
         blocking_history_TR04_883: 'Blocking history time 8',
         blocking_history_TR04_884: 'Blocking history time 9',
         blocking_history_TR04_885: 'Blocking history time 10',
         blocking_history_TR04_886: 'Blocking history time 11',
         blocking_history_TR04_887: 'Blocking history time 12',
         blocking_history_TR04_888: 'Blocking history time 13',
         blocking_history_TR04_889: 'Blocking history time 14',
         blocking_history_TR04_890: 'Blocking history time 15',
         blocking_history_TR04_891: 'Blocking history time 16',
         // Warmtapwater - TR04
         settings_dhw_warm_tap_water_TR04_title: 'Settings hot water',
         settings_dhw_warm_tap_water_TR04_300: 'DHW setpoint',
         settings_dhw_warm_tap_water_TR04_301: 'Preheat mode',
         settings_dhw_warm_tap_water_TR04_301_list: JSON.stringify({
           list: {
             0: 'Off',
             1: 'Anti freeze',
             2: 'Eco',
             3: 'Comfort',
             4: 'Dynamic (recommended)',
           },
         }),
         settings_dhw_warm_tap_water_TR04_302: 'Preheat Comfort setpoint',
         settings_dhw_warm_tap_water_TR04_303: 'Flow rate start threshold',
         settings_dhw_warm_tap_water_TR04_304: 'DHW Mode',
         settings_dhw_warm_tap_water_TR04_304_list: JSON.stringify({
           list: {
             0: 'Off',
             8: 'PID Controlled',
           },
         }),
         settings_dhw_warm_tap_water_TR04_305: 'DHW Primary Boost Exit Time',
         settings_dhw_warm_tap_water_TR04_306:
           'DHW Mode8 Dynamic Setpoint Active',
         settings_dhw_warm_tap_water_TR04_306_list: JSON.stringify({
           list: {
             0: 'Off',
             1: 'On',
           },
         }),
         settings_dhw_warm_tap_water_TR04_307: 'DHW Actual Setpoint',

         // Thermal disinfection - TR04
         settings_thermal_disinfection_TR04_title:
           'Settings Thermal disinfection',
         settings_thermal_disinfection_TR04_400: 'Aleg enabled',
         settings_thermal_disinfection_TR04_400_list: JSON.stringify({
           list: {
             0: 'Off',
             1: 'On',
           },
         }),
         settings_thermal_disinfection_TR04_401: 'Aleg burn time',
         settings_thermal_disinfection_TR04_402: 'Aleg burn time',
         settings_thermal_disinfection_TR04_403: 'Aleg wait time',

         // Heating - TR04
         heating_and_cooling_settings_heating_TR04_title: 'Settings heating',
         heating_and_cooling_settings_heating_TR04_500: 'CH Max Setpoint',
         heating_and_cooling_settings_heating_TR04_501: 'CH Min Setpoint',
         heating_and_cooling_settings_heating_TR04_502: 'OutdoorResetMin',
         heating_and_cooling_settings_heating_TR04_503: 'OutdoorResetMax',
         heating_and_cooling_settings_heating_TR04_504: 'WarmWeatherShut',
         heating_and_cooling_settings_heating_TR04_506:
           'Maximum primary return',
         heating_and_cooling_settings_heating_TR04_507:
           'Max Primary Return perc',
         heating_and_cooling_settings_heating_TR04_508:
           'Delta Prim CH Setpoint',
         heating_and_cooling_settings_heating_TR04_509: 'Delta Prim min',
         heating_and_cooling_settings_heating_TR04_510: 'Max Heating Temp',
         heating_and_cooling_settings_heating_TR04_514: 'CH Setpoint',
         heating_and_cooling_settings_heating_TR04_515: 'CH Mode',
         heating_and_cooling_settings_heating_TR04_515_list: JSON.stringify({
           list: {
             0: 'Off',
             1: 'On',
           },
         }),

         // Cooling - TR04
         heating_and_cooling_settings_cooling_TR04_title: 'Settings cooling',
         heating_and_cooling_settings_cooling_TR04_600: 'CoolTempSetpoint',
         heating_and_cooling_settings_cooling_TR04_601:
           'Cool MaxPrimary Return',
         heating_and_cooling_settings_cooling_TR04_602:
           'Cool % of Max Setpoint compensation',
         heating_and_cooling_settings_cooling_TR04_605: 'Max Cooling Temp',
         heating_and_cooling_settings_cooling_TR04_606: 'Cool setpoint min',
         heating_and_cooling_settings_cooling_TR04_607: 'Cool setpoint max',
         heating_and_cooling_settings_cooling_TR04_608: 'Condens protection',
         heating_and_cooling_settings_cooling_TR04_608_list: JSON.stringify({
           list: {
             0: 'Off',
             1: 'On',
           },
         }),
         heating_and_cooling_settings_cooling_TR04_609: 'Condens timer',
         heating_and_cooling_settings_cooling_TR04_610:
           'Condens Increased setpoint',
         heating_and_cooling_settings_cooling_TR04_611: 'Cooling enable',
         heating_and_cooling_settings_cooling_TR04_611_list: JSON.stringify({
           list: {
             0: 'Off',
             1: 'On',
           },
         }),

         // Secondary General - TR04
         heating_and_cooling_settings_secondary_general_TR04_title:
           'Settings general',
         heating_and_cooling_settings_secondary_general_TR04_512:
           'CH Min Pressure',
         heating_and_cooling_settings_secondary_general_TR04_513:
           'CH Max Pressure',
         heating_and_cooling_settings_secondary_general_TR04_603:
           'CoolHeatWaitingTime',
         heating_and_cooling_settings_secondary_general_TR04_604:
           '6-way valve waiting time',

         // Manual Operation - TR04
         settings_manual_operation_TR04_title: 'Manual control',
         settings_manual_operation_TR04_700: 'Manual control enable',
         settings_manual_operation_TR04_700_list: JSON.stringify({
           list: {
             0: 'Off',
             1: 'On',
           },
         }),
         settings_manual_operation_TR04_701: 'MCV-1 Manual control',
         settings_manual_operation_TR04_702: 'MCV-2 Manual control',
         settings_manual_operation_TR04_703: 'Manual (CH) Pump setting',
         settings_manual_operation_TR04_703_list: JSON.stringify({
           list: {
             0: 'Off',
             1: 'On',
           },
         }),
         settings_manual_operation_TR04_704: 'Manual 6-way valve position',
         settings_manual_operation_TR04_704_list: JSON.stringify({
           list: {
             0: 'Heating',
             1: 'Cooling',
           },
         }),
         settings_manual_operation_TR04_707: 'MCV-1 Manual control[%]',
         settings_manual_operation_TR04_708: 'MCV-2 Manual control[%]',
         settings_manual_operation_TR04_709: 'Manual control timeout',
         // DeltaP Parameters(LIN-PUMP)
         deltap_parameters_TR04_1870_1871: 'Customer installation pressure',
         deltap_parameters_TR04_1872_1873: 'DeltaP KV-Value',
         deltap_parameters_TR04_1874_1875: 'DeltaP Max Pressure',
         devices_partner_serial_number:
           'Distributor Serial Number heat interface unit',
         devices_partner_article_number_field:
           'Distributor Article number heat interface unit',
       };
